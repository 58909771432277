import './user_list.css'

import { Button, Form, message, Popconfirm, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import Request from '../../../request';
import { Link } from 'react-router-dom';

interface Item {
  key: string;
  name: string;
  add_time: string;
  is_active: boolean;
}

const App: React.FC = () => {

  let request = new Request({});

  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(0)
  const [pageSize, _] = useState(10)

  const [form] = Form.useForm();
  const [data, setData] = useState<any[]>([]);
  const [editingKey, setEditingKey] = useState('');

  const fetchData = (page: number) => {
    request.get('/api/v1/admin/users/?limit=' + pageSize + '&page=' + page).then((res: any)=>{
      console.log("res===", res)

      if(res.code === 0){
        setTotal(res.count)
        var originData = []
        for(var i=0; i<res.data.length; i++){
           var tmp = res.data[i]
           tmp["key"] = res.data[i].id
           tmp["date_joined"] = res.data[i].date_joined.slice(0, 19)
           originData.push(tmp)
        }
        setData(originData)
      }
    })
  }

  useEffect(()=>{
    fetchData(1)
  }, [])

  const resetItem = async (key: React.Key) => {
    console.log('deleteItem=', key)
    request.post('/api/v1/admin/users/reset_password_multiple/', {ids: [key]}).then((res: any)=>{
      if(res.code === 0){
        message.info('重置密码成功');
        fetchData(current)
      }else{
        message.info(res.msg);
      }
    })
  };

  const forbidItem = async (key: React.Key) => {
    console.log('deleteItem=', key)
    request.post('/api/v1/admin/users/set_inactive_multiple/', {ids: [key]}).then((res: any)=>{
      if(res.code === 0){
        message.info('禁用成功');
        fetchData(current)
      }else{
        message.info(res.msg);
      }
    })
  };

  const activeItem = async (key: React.Key) => {
    console.log('deleteItem=', key)
    request.post('/api/v1/admin/users/set_active_multiple/', {ids: [key]}).then((res: any)=>{
      if(res.code === 0){
        message.info('禁用成功');
        fetchData(current)
      }else{
        message.info(res.msg);
      }
    })
  };

  const columns = [
    {
      title: '名称',
      dataIndex: 'username',
      width: '15%',
      editable: false,
    },
    {
      title: '创建时间',
      dataIndex: 'date_joined',
      width: '18%',
      editable: false,
    },
    {
      title: '角色',
      dataIndex: 'role_name',
      width: '12%',
      editable: false,
    },
    {
      title: '联系电话',
      dataIndex: 'mobile',
      width: '15%',
      editable: false,
    },
    {
      title: '公司',
      dataIndex: 'company_name',
      width: '20%',
      editable: false,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_: any, record: Item) => {
        return (
          <div>
            <Link target="_blank" to={"/user/detail/" + record.key}>详情</Link>
            &nbsp;&nbsp;
            {
              record.is_active ?
              <Popconfirm title="确定禁用吗?" onConfirm={() => forbidItem(record.key)} style={{ marginRight: 8 }}>
                <a>禁用</a>
              </Popconfirm>:
              <Popconfirm title="确定启用吗?" onConfirm={() => activeItem(record.key)} style={{ marginRight: 8 }}>
                <a>启用</a>
              </Popconfirm>
            }

            &nbsp;&nbsp;
            <Popconfirm title="确定重置吗?" onConfirm={() => resetItem(record.key)} style={{ marginRight: 8 }}>
              <a>重置密码</a>
            </Popconfirm>
          </div>
        )
      },
    },
  ];

  const changePage = (page: any) => {
    console.log('changePage', page)
    setCurrent(page)
    fetchData(page)
  }

  const addUser = () => {
    const w = window.open('/user/add', '_blank');
    if(w){ w.focus()}
  }

  return (
    <div>
      <Button type="primary" className='oil-field-add-btn' onClick={addUser}>+增加用户</Button>
      <Form form={form} component={false}>
        <Table
          bordered
          dataSource={data}
          columns={columns}
          pagination={{
            onChange: changePage,
            total: total,
            current: current,
            pageSize: pageSize
          }}
        />
      </Form>
    </div>

  );
};

export default App;