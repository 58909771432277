import './company_list.css'

import { Button, Form, message, Popconfirm, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import Request from '../../../request';
import { Link } from 'react-router-dom';

interface Item {
  key: string;
  name: string;
  add_time: string;
  address: string;
}

const App: React.FC = () => {

  let request = new Request({});

  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(0)
  const [pageSize, _] = useState(10)

  const [form] = Form.useForm();
  const [data, setData] = useState<any[]>([]);

  const fetchData = (page: number) => {
    request.get('/api/v1/admin/companies/?limit=' + pageSize + '&page=' + page).then((res: any)=>{
      console.log("res===", res)

      if(res.code === 0){
        setTotal(res.count)
        var originData = []
        for(var i=0; i<res.data.length; i++){
           var tmp = res.data[i]
           tmp["key"] = res.data[i].id
           originData.push(tmp)
        }
        setData(originData)
      }
    })
  }

  useEffect(()=>{
    fetchData(1)
  }, [])

  const deleteItem = async (key: React.Key) => {
    console.log('deleteItem=', key)
    request.delete('/api/v1/admin/companies/' + key + '/').then((res: any)=>{
      if(res.code === 0){
        message.info('删除成功');
        fetchData(current)
      }else{
        message.info(res.msg);
      }
    })
  };
  
  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
      width: '20%',
      editable: true,
    },
    {
      title: '创建时间',
      dataIndex: 'add_time',
      width: '18%',
      editable: false,
    },
    {
      title: '联系人',
      dataIndex: 'person',
      width: '15%',
      editable: true,
    },
    {
      title: '联系电话',
      dataIndex: 'phone',
      width: '12%',
      editable: true,
    },
    {
      title: '地址',
      dataIndex: 'address',
      width: '25%',
      editable: true,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_: any, record: Item) => {
        return (
          <div>
            <Link target="_blank" to={"/company/detail/" + record.key}>详情</Link>
            &nbsp;&nbsp;
            <Popconfirm title="确定删除吗?" onConfirm={() => deleteItem(record.key)} style={{ marginRight: 8 }}>
              <a>删除</a>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const changePage = (page: any) => {
    console.log('changePage', page)
    setCurrent(page)
    fetchData(page)
  }

  const addCompany = () => {
    const w = window.open('/company/add', '_blank');
    if(w){ w.focus()}  
  }

  return (
    <div>
      <Button type="primary" className='company-add-btn' onClick={addCompany}>+增加公司</Button>

      <Form form={form} component={false}>
        <Table
          bordered
          dataSource={data}
          columns={columns}
          pagination={{
            onChange: changePage,
            total: total,
            current: current,
            pageSize: pageSize
          }}
        />
      </Form>

    </div>
  );
};

export default App;