import './dashboard2.css';

import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Dash2PieChart from '../Dash2PieChart/dash2piechart'
import Dash2PieChart2 from '../Dash2PieChart2/dash2piechart2'
import Dash2LineChart from '../Dash2LineChart/dash2linechart'
import Dash2PieChart3 from '../Dash2PieChart3/dash2piechart3'

import Clock from '../Clock/clock';

import Request from '../../request';
import cookie from 'react-cookies'
import { companyUnitProcess } from '../../utils/companyProcess';


var currentPage = 1


function Dashboard2() {

  const [summary, setSummary] = useState({
    oil_field_count: 0,
    make_oil_field_count: 0,
    make_oil_well_count: 0,
    oil_well_count: 0,
    processed_oil: 0,
    processed_water: 0,
    unmake_oil_field_count: 0,
    unmake_oil_well_count: 0
  })


  const [iot, setIot] = useState({
    height: "",
    water: "",
    temp: "",
    heat: "",
    lock: ""
  })

  const [oilWellList, setOilWellList] = useState<any[]>([])
  const [showSize] = useState(8)

  let [selectedIndex, setSelectedIndex] = useState(0)

  let request = new Request({});

  const [realtimeData, setRealtimeData] = useState({
    oil: 0,
    water: 0,
    runtime: "",
    oilCopy:0,
    oilUnit:"",
    waterCopy:0,
    waterUnit:"",
  })

  const [oilWellHistory, setOilWellHistory] = useState({
    oil: 0,
    water: 0,
    oilCopy: 0,
    waterCopy: 0,
    oilUnit: "",
    waterUnit: "",
  })

  const navigate = useNavigate();

  const width_x = 1394
  const height_y = 784

  const [size, setSize] = useState({
    width: document.documentElement.clientWidth,
    height: document.documentElement.clientWidth * height_y / width_x,
  })


  const [scaleRate, setScaleRate] = useState(1)

  const [oilPrice, setOilPrice] = useState("")
  // const [currentPage, setCurrentPage] = useState(1)

  var currentSize = showSize  // 最后一页可能少于每页的数量

  useEffect(()=>{
    let scaleRate = Math.round(size.width / width_x * 100) / 100

    // console.log('scale_rate=', scaleRate)
    setScaleRate(scaleRate)
  }, [size])


  const onResize = useCallback(() => {

    // console.log('width,height=', document.documentElement.clientWidth, document.documentElement.clientHeight)
    setSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientWidth * height_y / width_x,
    })
  }, [])

  useEffect(() => {
    // console.log('ssss=', size);
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize',  onResize)
    }
  }, [onResize])


  useEffect(() =>{
    if(!cookie.load('token')){
      navigate('/user/login')
    }

    request.get('/api/v1/report/summary/').then((res: any)=>{
        setSummary({...res.data})
    })
  }, [])

  const fetchOilWellList = () => {
    if(!cookie.load('token')){
      return
    }
    request.get('/api/v1/report/oil_well_list/?page=' + currentPage + '&limit=' + showSize).then((res: any)=>{
      setOilWellList(res.data)
      currentSize = res.data.length
      if(currentPage * showSize - res.total >= 0 && currentPage * showSize - res.total < showSize){
        currentPage = 1
      }else{
        currentPage += 1
      }
    })
  }

  useEffect(()=>{
    fetchOilWellList()
  }, [])

  const rollFunc = () => {
    selectedIndex = selectedIndex+1
    if(selectedIndex >= currentSize){
      fetchOilWellList()
      selectedIndex = 0
    }
    setSelectedIndex(selectedIndex)
  }
  let [timerNumber, setTimerNumber] = useState(10000)
  const rowOnClick = (index:any) => {
    selectedIndex = index
    if(selectedIndex >= currentSize){
      selectedIndex = 0
      fetchOilWellList()
    }
    setSelectedIndex(selectedIndex)
  }

  const rowMouseEnter = () => {
    setTimerNumber(2000000000)
  }
  const rowMouseLeave = () => {
    setTimerNumber(10000)
  }
  useEffect(()=>{
    let timer = setInterval(()=>{
      rollFunc()
    }, timerNumber)
    return ()=>{
      clearInterval(timer)
    }
  }, [timerNumber])


  useEffect(()=>{
    // console.log('oilWellList', oilWellList)
    if(oilWellList.length === 0){
      return
    }
    let request = new Request({});

    let oilWell = oilWellList[selectedIndex % oilWellList.length]
    // console.log('oilWell=', oilWell)

    request.get('/api/v1/report/iot_status/?oil_well_id=' + oilWell.id).then((res: any)=>{
      setIot({
        height: res.data.height,
        water:  res.data.water,
        temp: res.data.temp,
        heat: res.data.heat,
        lock: res.data.lock
      })
    })

    request.get('/api/v1/report/oil_well/' + oilWell.id + '/record_realtime/').then((res: any)=>{
      // console.log('realtime===', res.data);
      let OilObj:any = companyUnitProcess(res.data.oil)
      res.data.oilCopy = OilObj.number
      res.data.oilUnit = OilObj.companyUnit
      let waterObj:any = companyUnitProcess(res.data.water)
      res.data.waterCopy = waterObj.number
      res.data.waterUnit = waterObj.companyUnit

      setRealtimeData({...res.data})
    })

    request.get('/api/v1/oilprice/').then((res: any)=>{
      setOilPrice(res.data.price)
    })


  }, [selectedIndex, oilWellList])

  const goToDashboard = () => {
    navigate('/dashboard')
  }


  return (
    <div className='dashboard-container2' style={{ transform: `scale(${scaleRate})`, transformOrigin: 'top center', width: `${width_x}px` , height: `${height_y}px`  }}>
        <div className='dash2-title'>城市油田可视化大数据平台</div>

        <div className='dash2-datetime'><Clock /></div>
        <div className='dash2-real-price' onClick={goToDashboard}>实时油价：{oilPrice} 元/吨</div>

        <div className="dash2-pie-chart"><Dash2PieChart id='dash2-pie-chart' width='206px' height='236px' /></div>
        <div className="dash2-pie-chart2"><Dash2PieChart2 id='dash2-pie-chart2' width='206px' height='236px' /></div>

        <div className='dash2-total-count'>
          <div className='dash2-oilfield-count' onClick={goToDashboard}>{summary.oil_field_count}<span className='dash2-count-unit'>块</span></div>
          <div className='dash2-oilwell-count' onClick={goToDashboard}>{summary.oil_well_count}<span className='dash2-count-unit'>口</span></div>
          <div className='dash2-iot-count'>{summary.oil_well_count}<span className='dash2-count-unit'>台</span></div>
          <div className='dash2-sensor-count'>
            <div className='dash2-sensor-temp-count'>{summary.oil_well_count}</div>
            <div className='dash2-sensor-lock-count'>{summary.oil_well_count}</div>
            <div className='dash2-sensor-water-count'>0</div>
            <div className='dash2-sensor-height-count'>{summary.oil_well_count}</div>
          </div>
        </div>


        <div className='dash2-table'>
          <div className='dash2-table-header dash2-table-row'>
            <div className='dash2-table-column'>油井</div>
            <div className='dash2-table-column'>区域</div>
            <div className='dash2-table-column'>楼-层-门牌号</div>
            <div className='dash2-table-column'>油田</div>
            <div className='dash2-table-column'>联网状态</div>
          </div>
          {/* <div> */}
          <div onMouseEnter={rowMouseEnter} onMouseLeave={rowMouseLeave}>
          {
              oilWellList.map((data: any, index)=>{
                let iot_status = ''
                if(oilWellList[index].iot_status === '已联网'){
                  iot_status = 'dash2-table-column dash2-table-row-connected'
                }else if(oilWellList[index].iot_status === '断开'){
                  iot_status = 'dash2-table-column dash2-table-row-broken'
                }else{
                  iot_status = 'dash2-table-column'
                }

                if(index === selectedIndex % oilWellList.length){
                   return <div className='dash2-table-row dash2-selected-row' key={index} onClick={() => rowOnClick(index)}>
                    <div className='dash2-table-column'>{oilWellList[index].name}</div>
                    <div className='dash2-table-column'>{oilWellList[index].district}</div>
                    <div className='dash2-table-column'>{oilWellList[index].floor}</div>
                    <div className='dash2-table-column'>{oilWellList[index].oil_field_name}</div>
                    <div className={iot_status} >{oilWellList[index].iot_status}</div>
                  </div>
                }else{

                    return <div className='dash2-table-row' key={index} onClick={() => rowOnClick(index)}>
                    <div className='dash2-table-column'>{oilWellList[index].name}</div>
                    <div className='dash2-table-column'>{oilWellList[index].district}</div>
                    <div className='dash2-table-column'>{oilWellList[index].floor}</div>
                    <div className='dash2-table-column'>{oilWellList[index].oil_field_name}</div>
                    <div className={iot_status} >{oilWellList[index].iot_status}</div>
                  </div>

                }
              })
            }
            </div>
        </div>


         <div className='dash2-selected-item-iot'> {oilWellList.length>0?oilWellList[selectedIndex%oilWellList.length].name: ""} IOT状态</div>

         <div className='dash2-selected-item-height'>
             <div className={iot.height === "正常" ? "dash2-selected-item-height-bg-working" : (iot.height === '未工作' ? "dash2-selected-item-height-bg-notworking" : "dash2-selected-item-height-bg-broken")}></div>
             <div className='dash2-selected-item-height-text'>液位上报</div>
             <div className={iot.height === "正常" ? "dash2-selected-item-height-status-working" : (iot.height === '未工作' ? "dash2-selected-item-height-status-notworking" : "dash2-selected-item-height-status-broken")}><div className={iot.height === "正常" ? "dash2-selected-item-status-icon-working" : (iot.height === '未工作' ? "dash2-selected-item-status-icon-notworking" : "dash2-selected-item-status-icon-broken")}></div>{iot.height}</div>
         </div>


         <div className='dash2-selected-item-water'>
             <div className={iot.water === "正常" ? "dash2-selected-item-water-bg-working" : (iot.water === '未工作' ? "dash2-selected-item-water-bg-notworking" : "dash2-selected-item-water-bg-broken")}></div>
             <div className='dash2-selected-item-water-text'>智能水表上报</div>
             <div className={iot.water === "正常" ? "dash2-selected-item-water-status-working" : (iot.water === '未工作' ? "dash2-selected-item-water-status-notworking" : "dash2-selected-item-water-status-broken")}><div className={iot.water === "正常" ? "dash2-selected-item-status-icon-working" : (iot.water === '未工作' ? "dash2-selected-item-status-icon-notworking" : "dash2-selected-item-status-icon-broken")}></div>{iot.water}</div>
         </div>


         <div className='dash2-selected-item-temp'>
             <div className={iot.temp === "正常" ? "dash2-selected-item-temp-bg-working" : (iot.temp === '未工作' ? "dash2-selected-item-temp-bg-notworking" : "dash2-selected-item-temp-bg-broken")}></div>
             <div className='dash2-selected-item-temp-text'>温度上报</div>
             <div className={iot.temp === "正常" ? "dash2-selected-item-temp-status-working" : (iot.temp === '未工作' ? "dash2-selected-item-temp-status-notworking" : "dash2-selected-item-temp-status-broken")}><div className={iot.temp === "正常" ? "dash2-selected-item-status-icon-working" : (iot.temp === '未工作' ? "dash2-selected-item-status-icon-notworking" : "dash2-selected-item-status-icon-broken")}></div>{iot.temp}</div>
         </div>



         <div className='dash2-selected-item-heat'>
             <div className={iot.heat === "正常" ? "dash2-selected-item-heat-bg-working" : (iot.heat === '未工作' ? "dash2-selected-item-heat-bg-notworking" : "dash2-selected-item-heat-bg-broken")}></div>
             <div className='dash2-selected-item-heat-text'>电热丝</div>
             <div className={iot.heat === "正常" ? "dash2-selected-item-heat-status-working" : (iot.heat === '未工作' ? "dash2-selected-item-heat-status-notworking" : "dash2-selected-item-heat-status-broken")}><div className={iot.heat === "正常" ? "dash2-selected-item-status-icon-working" : (iot.heat === '未工作' ? "dash2-selected-item-status-icon-notworking" : "dash2-selected-item-status-icon-broken")}></div>{iot.heat}</div>
         </div>


         <div className='dash2-selected-item-lock'>
             <div className={iot.lock === "正常" ? "dash2-selected-item-lock-bg-working" : (iot.lock === '未工作' ? "dash2-selected-item-lock-bg-notworking" : "dash2-selected-item-lock-bg-broken")}></div>
             <div className='dash2-selected-item-lock-text'>智能锁</div>
             <div className={iot.lock === "正常" ? "dash2-selected-item-lock-status-working" : (iot.lock === '未工作' ? "dash2-selected-item-lock-status-notworking" : "dash2-selected-item-lock-status-broken")}><div className={iot.lock === "正常" ? "dash2-selected-item-status-icon-working" : (iot.lock === '未工作' ? "dash2-selected-item-status-icon-notworking" : "dash2-selected-item-status-icon-broken")}></div>{iot.lock}</div>
         </div>


         <div className='dash2-selected-item-output'>{oilWellList.length>0?oilWellList[selectedIndex%oilWellList.length].name: ""} 油量/水量分布</div>

         <div className="dash2LineChart"><Dash2LineChart id='dash2LineChart' oilWellId={oilWellList.length>0?oilWellList[selectedIndex%oilWellList.length].id: ""} setOilWellHistory={setOilWellHistory} width='820px' height='235px' /></div>

         {/* 油井投产实时状态 */}
         <div className="dash2PieChart3"><Dash2PieChart3 id='dash2PieChart3' width='280px' height='205px' summary={summary}/></div>

         <div className='dash2PieChart3Bg'>
           <div className='dash2PieChart3BgCircle'></div>
         </div>


         <div className='dash2-runtime-name'>{oilWellList.length>0?oilWellList[selectedIndex%oilWellList.length].name: ""}</div>
         <div className='dash2-runtime-text'>当前周期运行时间：<span className='runtime'>{realtimeData.runtime.split('h')[0]}h</span></div>

         <div className='dash2-total-oil'>
             <div className='dash2-total-oil-number'>{realtimeData.oilCopy.toFixed(2)}<span className='dash2-total-oil-unit'>{realtimeData.oilUnit}</span></div>
             <div className='dash2-total-oil-text'>总油量</div>
         </div>


         <div className='dash2-total-water'>
             <div className='dash2-total-water-number'>{realtimeData.waterCopy.toFixed(2)}<span className='dash2-total-water-unit'>{realtimeData.waterUnit}</span></div>
             <div className='dash2-total-water-text'>总水量</div>
         </div>

         <div className='dash2-total-dealt-oil'>{oilWellHistory.oilCopy.toFixed(2)}<span className='dash2-total-dealt-unit'>{oilWellHistory.oilUnit}</span></div>
         <div className='dash2-total-dealt-water'>{oilWellHistory.waterCopy.toFixed(2)}<span className='dash2-total-dealt-unit'>{oilWellHistory.waterUnit}</span></div>



    </div>
  )
}

export default Dashboard2;
