import './oil_map.css'
import { Form, Table} from 'antd';
import { useEffect, useState } from 'react';
import Request from '../../../request';
import AMapLoader from '@amap/amap-jsapi-loader';

import axios from "axios";

const App = (props) => {

  const jsapi_key = 'eebfecd95f29b80cddc97815fd18e9ec';
  const webservice_key = 'b53c6a14b208880b85b7d6249455efce';

  let request = new Request({});
  const [data, setData] = useState([]);
  const [counter, setCounter] = useState(0);

  let address = window.location.search.substring(1).split('=')[1]

  const fetchData = (oil_well_id) => {

    request.get('/api/v1/admin/oilwells/positions/?city=' + address).then((res)=>{
      console.log("data===", res)

      if(res.code === 0){
        var originData = []
        for(var i=0; i<res.data.length; i++){
           var tmp = res.data[i]
           tmp["key"] = res.data[i].id
           originData.push(tmp)
        }
        setData(originData)
        setCounter(1)
      }
    })
  }

  const getLocation = () => {
    // address = "金华市"
    let url = 'https://restapi.amap.com/v3/geocode/geo?key=' + webservice_key + '&address=' + address
    console.log(url)
    axios.get(url).then((res)=>{
      let lon_lat = res['data']['geocodes'][0]['location']
      console.log(res)
      initMap(lon_lat.split(','))
    })
  }
  const initMap = (location)  => {
    AMapLoader.load({
      key: jsapi_key, // 申请好的Web端开发者Key，首次调用 load 时必填
      version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
      plugins: [''], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    })
      .then(AMap => {
        let map = new AMap.Map('map-container', {
          // 设置地图容器id
          viewMode: '3D', // 是否为3D地图模式
          zoom: 13, // 初始化地图级别
          center: location, // 初始化地图中心点位置
        });

        console.log('datax=', data)

        for(var i=0; i<data.length; i++){
          const marker = new AMap.Marker({
            position: new AMap.LngLat(data[i]['lbs_lng'],data[i]['lbs_lat']),
            offset: new AMap.Pixel(-10, -10),
            // icon: 'https://webapi.amap.com/theme/v1.3/markers/n/mark_b.png', // 添加 Icon 图标 URL
            icon: require("../../../mark.png") , // 添加 Icon 图标 URL
            label: {
              content: data[i]['name']
            },
          });
        
          map.add(marker);
        }
   
      })
      .catch(e => {
        console.log(e);
      });
  }

  useEffect(()=>{
    fetchData(props.id)
    getLocation()
  }, [counter])



  return (
     <div id="map-container" className="map" style={{ height: window.innerHeight }}></div>
  )
};

export default App;