import './upgrade_task_list.css'

import { Button, Form, message, Popconfirm, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import Request from '../../../request';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';


interface Item {
  key: string;
  name: string;
  add_time: string;
  is_active: boolean;
  status: number;
}

const App: React.FC = () => {

  let request = new Request({});
  const navigate = useNavigate();

  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(0)
  const [pageSize, _] = useState(10)

  const [form] = Form.useForm();
  const [data, setData] = useState<any[]>([]);
  const [editingKey, setEditingKey] = useState('');

  const fetchData = (page: number) => {
    request.get('/api/v1/admin/iots/upgrade/?field=-id' + '&limit=' + pageSize + '&page=' + page).then((res: any)=>{

      if(res.code === 0){
        setTotal(res.count)
        var originData = []
        for(var i=0; i<res.data.length; i++){
           var tmp = res.data[i]
           tmp["key"] = res.data[i].id
          //  tmp["date_joined"] = res.data[i].date_joined.slice(0, 19)
           originData.push(tmp)
        }
        setData(originData)
      }
    })
  }

  useEffect(()=>{
    fetchData(1)
  }, [])

  const togtherItem = async (page: number) => {
    request.post('/api/v1/admin/iots/softwares/sync/', {limit: pageSize, page: page,}).then((res: any)=>{
      if(res.code === 0){
        message.info('同步成功');
        fetchData(current)
      }else{
        message.info(res.msg);
      }
    })
  };
  const manualUpgradeItem = async (key: React.Key) => {
    request.post('/api/v1/admin/iots/upgrade/'+key+'/execute/').then((res: any)=>{
      if(res.code === 0){
        message.info('同步成功');
        fetchData(current)
      }else{
        message.info(res.msg);
      }
    })
  };


  const deleteItem = async (key: React.Key) => {
      request.delete('/api/v1/admin/iots/upgrade/' + key + '/').then((res: any)=>{
      if(res.code === 0){
        message.info('删除成功');
        fetchData(current)
      }else{
        message.info(res.msg);
      }
    })
  };

  const columns = [
    {
      title: '任务名称',
      dataIndex: 'name',
      width: '25%',
      editable: false,
    },
    {
      title: '升级包版本号',
      dataIndex: 'new_version',
      width: '12%',
      editable: false,
    },
    {
      title: '任务状态',
      dataIndex: 'status_name',
      width: '15%',
      editable: false,
    },
    {
      title: '任务内容',
      dataIndex: 'description',
      width: '30%',
      editable: false,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_: any, record: Item) => {
        return (
          <div>
            <Link target="_blank" to={"/upgrade_task/detail/" + record.key}>详情</Link>
            &nbsp;&nbsp;
            {
              record.status != 1 ?
              '':
              <Popconfirm disabled={record.status != 1 ? true : false} title="确定升级吗?" onConfirm={() => manualUpgradeItem(record.key)} style={{ marginRight: 8 }}>
                <a>手动升级</a>
              </Popconfirm>
            }

            &nbsp;&nbsp;
            {/* <Popconfirm title="确定取消吗?" onConfirm={() => cancelItem(record.key)} style={{ marginRight: 8 }}>
              <a>取消</a>
            </Popconfirm> */}
            &nbsp;&nbsp;
            <Popconfirm title="确定删除吗?" onConfirm={() => deleteItem(record.key)} style={{ marginRight: 8 }}>
              <a>删除</a>
            </Popconfirm>
          </div>
        )
      },
    },
  ];

  const changePage = (page: any) => {
    setCurrent(page)
    fetchData(page)
  }

  const syncStart = (current: number) => {
    // setCurrent(current)
    togtherItem(current)
  }
  const addTask = () => {
    navigate('/upgrade_task/add');
    // const w = window.open('/upgrade_task/add', '_blank');

    // if(w){ w.focus()}
  }
  return (
    <div>
      <Button type="primary" className='oil-field-add-btn' onClick={ () => addTask()}>创建任务</Button>
      <Form form={form} component={false}>
        <Table
          bordered
          dataSource={data}
          columns={columns}
          pagination={{
            onChange: changePage,
            total: total,
            current: current,
            pageSize: pageSize
          }}
        />
      </Form>
    </div>

  );
};

export default App;