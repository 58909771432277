import React, { Component, useEffect } from 'react'
import * as echarts from 'echarts/core';
import cookie from 'react-cookies'

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import Request from '../../request';
import { COLOR_LIST, COLOR_REVERSE_LIST } from '../../config';

echarts.use([
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout
  ]);

  // 油井分布
 function  Dash2PieChart2(props: any) {
    //  console.log("props=", props);
    useEffect(()=>{
        let user = cookie.load('user')
        if(!user){
          return
        }
        let myChart_exist = echarts.getInstanceByDom(document.getElementById(props.id) as HTMLElement);
        if (myChart_exist != undefined) {
            return
        }

        let myChart = echarts.init(document.getElementById(props.id) as HTMLElement, {renderer: 'svg'}, {devicePixelRatio: 2.5})
        let request = new Request({});
        let url = '/api/v1/report/zone/'
        if(user.province){
          url = '/api/v1/report/zone/?type=province&name=' + user.province
        }
        request.get(url).then((res: any)=>{
          var legendDataArray = []
          var seriesDataArray = []

          for(var i=0; i<res.data.length; i++){
            let legend = {
              name: res.data[i].name,
              textStyle: {
                  color: COLOR_REVERSE_LIST[i]
              }
            }

            legendDataArray.push(legend)

           let  series = {
              value: res.data[i].oil_well_count,
              name: res.data[i].name,
              textStyle: {
                color: COLOR_LIST[i]
              },
              isActive: false
            }
            seriesDataArray.push(series)
          }

          let option = {
            tooltip: {
              trigger: 'item'
            },
            legend: {
              icon: "rect",
              x: 'left',
              y: 'bottom',
              itemHeight: 10,
              itemWidth: 14,
              data: legendDataArray
            },

            series: [
              {
                name: '油井',
                type: 'pie',
                radius: ['55%', '70%'],
                label: {
                  show: true,
                  normal: {
                    position: 'outside',
                    fontSize: 10,
                    color: 'auto',
                    formatter: function(params:any) {
                      // console.log(params)
                      let color:any = params.color;
                      return params.value;
                      // return `${color}|${params.value}`;
                    }
                  }
                },
                labelLine: {
                  length: 10,
                  length2: 5,
                  smooth: true
                },
                data: seriesDataArray,
                center: ["50%", "35%"],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              },
              {
                name: "油井",
                type: "pie",
                radius: ['51%', '52%'],
                center: ["50%", "35%"],
                itemStyle: {
                  color: 'rgba(255, 255, 255, 0.3)'
                },
                label: {
                  show: false,
                },
                emphasis: {
                  disabled: true,
                },
                data: seriesDataArray,
              },
              {
                name: "油井",
                type: "pie",
                radius: [0, '50%'],
                center: ["50%", "35%"],
                itemStyle: {
                  color: (params: any) => {
                    // console.log(params,'params')
                    if (params.data.isActive) {
                      return "rgba(255, 255, 255, 0.3)";
                    } else {
                      return "rgba(0, 0, 0, 0)";
                    }
                  },
                },
                label: {
                  show: false,
                },
                emphasis: {
                  disabled: true,
                },
                data: seriesDataArray,
              },
            ],
            color: COLOR_REVERSE_LIST,

          };
          myChart.setOption(option)
          myChart.on("mouseover", (params) => {
            if (params.seriesType === "pie" && params.seriesName === "油井") {
              const optionOne:any = myChart.getOption();
               // 鼠标移入关闭默认展示第一项效果
               optionOne.series[0].label.show = true;
               optionOne.series[0].data[0].isActive = false;
               optionOne.series[2].data[0].isActive = false;
              // 显示鼠标移入项的效果
              optionOne.series[0].data[params.dataIndex].isActive = true;
              optionOne.series[2].data[params.dataIndex].isActive = true;
              myChart.setOption(optionOne);
            }
          });
          myChart.on("mouseout", (params) => {
            if (params.seriesType === "pie" && params.seriesName === "油井") {
              // 鼠标离开每一项后关闭移入效果
              const optionTwo:any = myChart.getOption();
              optionTwo.series[0].data[params.dataIndex].isActive = false;
              optionTwo.series[2].data[params.dataIndex].isActive = false;
              myChart.setOption(optionTwo);
            } else if (params.componentType === "graphic") {
              // 鼠标离开echarts整体后展示默认效果,即默认选中第一项
              const optionThree:any = myChart.getOption();
              optionThree.series[0].label.show = true;
              optionThree.series[0].data[0].isActive = true;
              optionThree.series[2].data[0].isActive = true;
              myChart.setOption(optionThree);
            }
          });
        })

    })


    return (
        <div id={props.id} style={{ width: props.width, height: props.height }}></div>
    );

}


export default Dash2PieChart2
