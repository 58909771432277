import React, { useEffect } from 'react'
import * as echarts from 'echarts/core';
import cookie from 'react-cookies'
import 'echarts-gl';

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  VisualMapComponent,
  ToolboxComponent,
} from 'echarts/components';
import { PieChart, BarChart, MapChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

import china from '../../map_json/china.json';
import anhui from "../../map_json/anhui.json";
import aomen from "../../map_json/aomen.json";
import beijing from "../../map_json/beijing.json";
import chongqing from "../../map_json/chongqing.json";
import fujian from "../../map_json/fujian.json";
import gansu from "../../map_json/gansu.json";
import guangdong from "../../map_json/guangdong.json";
import guangxi from "../../map_json/guangxi.json";
import guizhou from "../../map_json/guizhou.json";
import hainan from "../../map_json/hainan.json";
import hebei from "../../map_json/hebei.json";
import heilongjiang from "../../map_json/heilongjiang.json";
import henan from "../../map_json/henan.json";
import hubei from "../../map_json/hubei.json";
import hunan from "../../map_json/hunan.json";
import jiangsu from "../../map_json/jiangsu.json";
import jiangxi from "../../map_json/jiangxi.json";
import jilin from "../../map_json/jilin.json";
import liaoning from "../../map_json/liaoning.json";
import neimenggu from "../../map_json/neimenggu.json";
import ningxia from "../../map_json/ningxia.json";
import qinghai from "../../map_json/qinghai.json";
import shandong from "../../map_json/shandong.json";
import shanghai from "../../map_json/shanghai.json";
import shanxi2 from "../../map_json/shanxi (2).json";
import shanxi from "../../map_json/shanxi.json";
import sichuan from "../../map_json/sichuan.json";
import taiwan from "../../map_json/taiwan.json";
import tianjin from "../../map_json/tianjin.json";
import xianggang from "../../map_json/xianggang.json";
import xinjiang from "../../map_json/xinjiang.json";
import xizang from "../../map_json/xizang.json";
import yunnan from "../../map_json/yunnan.json";
import zhejiang from "../../map_json/zhejiang.json";

import Request from '../../request';


echarts.use([
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    PieChart,
    BarChart,
    MapChart,
    CanvasRenderer,
    LabelLayout,
    GridComponent,
    VisualMapComponent,
    ToolboxComponent
  ]);


const provinces = {
  台湾省: taiwan,
  河北省: hebei,
  山西省: shanxi,
  辽宁省: liaoning,
  吉林省: jilin,
  黑龙江省: heilongjiang,
  江苏省: jiangsu,
  浙江省: zhejiang,
  安徽省: anhui,
  福建省: fujian,
  江西省: jiangxi,
  山东省: shandong,
  河南省: henan,
  湖北省: hubei,
  湖南省: hunan,
  广东省: guangdong,
  海南省: hainan,
  四川省: sichuan,
  贵州省: guizhou,
  云南省: yunnan,
  陕西省: shanxi2,
  甘肃省: gansu,
  青海省: qinghai,
  新疆维吾尔自治区: xinjiang,
  广西省: guangxi,
  内蒙古自治区: neimenggu,
  宁夏回族自治区: ningxia,
  西藏自治区: xizang,
  北京市: beijing,
  天津市: tianjin,
  上海市: shanghai,
  重庆市: chongqing,
  香港特别行政区: xianggang,
  澳门特别行政区: aomen,
};


 function  MyMapChart(props: any) {

    let setParentZone = props.setZone

    const renderMap = (name: string, data: any, mapData: any, myChart: any) => {
      // 图标指定数据
      echarts.registerMap(name, data);

      myChart.setOption({}, true);//下转之后销毁上层地图
      const option = {
        // 图标标题
        // title: {
        //   text: "地图",
        // },
        // 鼠标移入显示的内容
        tooltip: {
          trigger: "item",
          enterable: true, //鼠标是否可进入提示框浮层中
          // backgroundColor: '#04284e',
          // borderColor: '#75DAEF',
          className: 'custom-tooltip-box',
          formatter: function(params: any) {
            let htmlStr:any = ''
              const param = params;
              htmlStr += '<div class="custom-tooltip-style">';
              htmlStr += '<div style="padding:10px 20px 10px 55px">';

            if (params.data.oil_field_count || params.data.oil_well_count){
                htmlStr += '<div style="color:white;"><span style="color:#1FC6FF;">' + params.data.name + '</span></div>';
                htmlStr += '<div style="color:white;"><span>油田数</span><span style="color:#00FFDF;margin-left:20px;">' + params.data.oil_field_count + '块</span></div>';
                htmlStr += '<div style="color:white;"><span>油井数</span><span style="color:#00FFDF;margin-left:20px;">' + params.data.oil_well_count + '口</span></div>';
              // return params.data.name + '</br>油田数:' + params.data.oil_field_count + '</br>油井数:' + params.data.oil_well_count ;
            }else{
                htmlStr += '<div style="color:white;"><span style="color:#1FC6FF;">' + params.data.name + '</span></div>';
                htmlStr += '<div style="color:white;"><span>油田数</span><span style="color:#00FFDF;margin-left:20px;">' + 0 + '块</span></div>';
                htmlStr += '<div style="color:white;"><span>油井数</span><span style="color:#00FFDF;margin-left:20px;">' + 0 + '口</span></div>';

            }
            htmlStr += '</div>';
            htmlStr += '</div>';
            return htmlStr;
          }
        },
        visualMap: {
          min: 0,
          max: 20,
          bottom: 80,
          left: 10,
          inRange: {
            color: ['lightskyblue','yellow', 'orangered']
          }
        },
        geo3D: [
          {
            // 指定图标为地图
            // type: "map",
            // 设置数据
            map: name,
            regionHeight: 0,
            left: 0,
            right: 0,
            center: [0, 0, 0],
            top: -30,
            bottom: -30,
            zoom: 1.3, // 改变这个值的大小就可以了
            zlevel: -1, // 必须设置，
            viewControl: { // 调整合适的角度显示
                distance: name==='china'? 100: 145,
                alpha: 50,
                beta: 0,
                // 限制视角，使不能旋转缩放平移
                rotateSensitivity: 0,
                zoomSensitivity: 0,
                panSensitivity: 0,
            },
            itemStyle: {
              normal:{
                color: {
                  type: 'radial',
                  x: 0.5,
                  y: 0.5,
                  r: 0.8,
                  colorStops: [{
                      offset: 0,
                      color: 'RGBA(18, 149, 169, 1)' // 0% 处的颜色
                  }, {
                      offset: 1,
                      color: 'RGBA(5, 103, 160, 1)' // 100% 处的颜色
                  }],
                  globalCoord: false // 缺省为 false
              },
              // color: '#116394',
              borderColor: '#7ED6FF'
            },
          },
            // label: {
            //   show: name==='china'? false: true,
            // },
            label: {
              show: false,
              distance: 5,
              textStyle: {
                 // color: 'black',
                 // 设置label背景颜色透明
                 backgroundColor: 'rgba(0, 0, 0, 0)'
              }
            },
            emphasis: {
              label: {
                show: name==='china'? false: true
              },
              itemStyle: {
                color: 'RGBA(18, 212, 207, 0.9)'
              }
            },
            z:1
          },
        ],
        series: [
          {
            type: "map3D",
            map: name,
            // roam: false,
            left: 0,
            right: 0,
            center: [0, 0, 0],
            top: -30,
            bottom: -30,
            // type: "map",
            zoom: 1.5, // 改变这个值的大小就可以了
            // 跟geo关联
            geoIndex: 0,
            zlevel: -1, // 必须设置，
            viewControl: {// 须与geo3D中相同，
              distance: name==='china'? 100: 145,
              alpha: 50,
              beta: 0,
                // 限制视角，使不能旋转缩放平移
              rotateSensitivity: 0,
              zoomSensitivity: 0,
              panSensitivity: 0,
            },
            // boxDepth: 100,//地图倾斜度
            // regionHeight: 4,//地图高度
            itemStyle: {            // 三维地理坐标系组件 中三维图形的视觉属性，包括颜色，透明度，描边等。
              color: 'rgba(5, 103, 160,0.8)',// 地图板块的颜色  RGBA(5, 103, 160, 1)
              opacity: 1,                 // 图形的不透明度 [ default: 1 ]
              borderWidth: 0.5,           // (地图板块间的分隔线)图形描边的宽度。加上描边后可以更清晰的区分每个区域   [ default: 0 ]
              borderColor: '#7ED6FE',         // 图形描边的颜色。[ default: #333 ]
            },
            label: {
              show: name==='china'? false: true,
              distance: 5,
              textStyle: {
                // color: 'black',
                // 设置label背景颜色透明
                backgroundColor: 'rgba(0, 0, 0, 0)'
              }
            },
            emphasis: {
              label: {
                show: name==='china'? false: true
              },
              itemStyle: {
                color: 'RGBA(18, 212, 207, 0.8)'//RGBA(18, 212, 207, 1)
              }
            },
            // 设置人口的数据  用于显示在地图上
            data: mapData
          },
        ],
      };
      myChart.setOption(option, true);

    }

    useEffect(()=>{
        let myChart = echarts.getInstanceByDom(document.getElementById(props.id) as HTMLElement);
        if (myChart == null || myChart == undefined) {
          myChart = echarts.init(document.getElementById(props.id) as HTMLElement, {renderer: 'svg'}, {devicePixelRatio: 2.5})
        }

        let request = new Request({});

        let user = cookie.load('user')

        if(!user){
          return
        }


        console.log('user.province', user.province)

        if(user.province){
          request.get('/api/v1/report/zone/?type=province&name='+ user.province).then((res: any)=>{
            res.data.forEach((item: any, index: number, array: any) => {  // 三个参数分别为：子元素，索引，原数组
              array[index]['value'] = item['oil_field_count']
            })
            renderMap(user.province, provinces[user.province], res.data, myChart);
            if(myChart){
              myChart.off("click")
              myChart.on("click", function (params) {
                window.location.href='/oil_map?city=' + params.name
              })
            }
          })
        }else{
          request.get('/api/v1/report/zone/?type=country&?name=中国').then((res: any)=>{
            res.data.forEach((item: any, index: number, array: any) => {  // 三个参数分别为：子元素，索引，原数组
              array[index]['value'] = item['oil_field_count']
            })
            renderMap('china', china, res.data, myChart)

            let zoneType = 'country'
            if(myChart){
              myChart.on("dblclick",function(e:any){
                console.log(e,'e')
              })
              myChart.off("click")
              myChart.on("click", function (params) {
                // 拿到点击的哪一个省份 用provinces里面对象的数据带到init方法里面用于渲染省份
                if(zoneType==='province'){
                  return
                }

                zoneType = 'province'
                request.get('/api/v1/report/zone/?type=province&name='+params.name).then((res: any)=>{
                  res.data.forEach((item: any, index: number, array: any) => {  // 三个参数分别为：子元素，索引，原数组
                    array[index]['value'] = item['oil_field_count']
                  })
                  setParentZone({type: 'province', name: params.name})
                  renderMap(params.name, provinces[params.name], res.data, myChart);
                  if(myChart){
                    myChart.off("click")
                    myChart.on("click", function (params) {
                      window.location.href='/oil_map?city=' + params.name
                    })
                  }
                })
              });
            }
          })
        }


    }, [props.backToChinaCount])

    return (
        <div id={props.id} style={{ width: props.width, height: props.height }}></div>
    );

}


export default MyMapChart
