import './user_detail.css'
import * as echarts from 'echarts/core';
import { Form, Input, InputNumber, message} from 'antd';
import React, { useEffect, useState } from 'react';
import Request from '../../../request';
import { COLOR_LIST, COLOR_REVERSE_LIST } from '../../../config';



const App = (props: any) => {
  console.log('props=', props)

  let request = new Request({});
  const [data, setData] = useState<any>({});

  const fetchData = (id: string) => {
    request.get('/api/v1/admin/users/' + id + '/').then((res: any)=>{
      if(res.code === 0){
        console.log('res=', res.data)
        setData(res.data)
      }
    })
  }



  useEffect(()=>{
    fetchData(props.id)
  }, [])


  return (
    <div className="user-detail-container">
      <div className="user-detail-header">
        <div>用户详情</div>
        <div className="user-detail-info-list">
          <div className="user-detail-info-item">ID:{data.id}</div>
          <div className="user-detail-info-item">名称:{data.name}</div>
          <div className="user-detail-info-item">角色:{data.role_name}</div>
          <div className="user-detail-info-item">创建时间:{data.date_joined}</div>
          <div className="user-detail-info-item">隶属公司:{data.company_name}</div>
          <div className="user-detail-info-item">工号:{data.employee_number}</div>
          <div className="user-detail-info-item">是否活跃:{data.is_active ? "是": "否"}</div>
          <div className="user-detail-info-item">联系电话:{data.mobile}</div>
        </div>
      </div>
    </div>
  );
};

export default App;