import './company_detail.css'
import * as echarts from 'echarts/core';
import { Form, Input, InputNumber, message} from 'antd';
import React, { useEffect, useState } from 'react';
import Request from '../../../request';
import { COLOR_LIST, COLOR_REVERSE_LIST } from '../../../config';



const App = (props: any) => {
  console.log('props=', props)

  let request = new Request({});
  const [data, setData] = useState<any>({});

  const fetchData = (id: string) => {
    request.get('/api/v1/admin/companies/' + id + '/').then((res: any)=>{
      if(res.code === 0){
        console.log('res=', res.data)
        setData(res.data)
      }
    })
  }



  useEffect(()=>{
    fetchData(props.id)
  }, [])


  return (
    <div className="company-detail-container">
      <div className="company-detail-header">
        <div>公司详情</div>
        <div className="company-detail-info-list">
          <div className="company-detail-info-item">ID:{data.id}</div>
          <div className="company-detail-info-item">名称:{data.name}</div>
          <div className="company-detail-info-item">昵称:{data.nickname}</div>
          <div className="company-detail-info-item">创建时间:{data.add_time}</div>
          <div className="company-detail-info-item">油田数量:{data.oil_field_count}</div>
          <div className="company-detail-info-item">油井数量:{data.oil_well_count}</div>
          <div className="company-detail-info-item">详细地址:{data.address}</div>
          <div className="company-detail-info-item">联系电话:{data.phone}</div>
          <div className="company-detail-info-item">员工数量:{data.user_count}</div>
        </div>
      </div>
    </div>
  );
};

export default App;