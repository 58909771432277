import React, { useEffect } from 'react'
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components';
import cookie from 'react-cookies'
import { PieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

import Request from '../../request';
import { COLOR_LIST, COLOR_REVERSE_LIST } from '../../config';


echarts.use([
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout
  ]);

  // 油田分布
 function  Dash2PieChart(props: any) {
    useEffect(()=>{
        let user = cookie.load('user')
        if(!user){
          return
        }
        let myChart_exist = echarts.getInstanceByDom(document.getElementById(props.id) as HTMLElement);
        if (myChart_exist !== undefined) {
            return
        }

        let myChart = echarts.init(document.getElementById(props.id) as HTMLElement, {renderer: 'svg'}, {devicePixelRatio: 2.5})

        let request = new Request({});

        let url = '/api/v1/report/zone/'
        if(user.province){
          url = '/api/v1/report/zone/?type=province&name=' + user.province
        }
        request.get(url).then((res: any)=>{

          var legendDataArray = []
          var seriesDataArray = []

          for(var i=0; i<res.data.length; i++){
            let legend = {
              name: res.data[i].name,
              textStyle: {
                  color: COLOR_REVERSE_LIST[i]
              }
            }

            legendDataArray.push(legend)

           let  series = {
              value: res.data[i].oil_field_count,
              name: res.data[i].name,
              textStyle: {
                color: COLOR_LIST[i]
              }
            }
            seriesDataArray.push(series)
          }

          let option = {
            tooltip: {
              trigger: 'item'
            },
            legend: {
              icon: "rect",
              x: 'left',
              y: 'bottom',
              itemHeight: 10,
              itemWidth: 14,
              data: legendDataArray
            },

            color: COLOR_REVERSE_LIST,
            series: [
              {
                name: '油田',
                type: 'pie',
                radius: ['0%', '70%'],

                label: {
                  show: true,
                  normal: {
                    position: 'outside',
                    fontSize: 10,
                    color: 'auto',
                    formatter: function(params:any) {
                      // console.log(params)
                      let color:any = params.color;
                      return params.value;
                      // return `${color}|${params.value}`;
                    }
                  }
                },

                labelLine: {
                  length: 25,
                  length2: 10,
                  smooth: true
                },
                data: seriesDataArray,
                center: ["50%", "35%"],
                emphasis: {
                  itemStyle: {
                    shadowColor: 'rgba(0, 123, 237, 0.7)',
                    shadowBlur: 10,
                    shadowOffsetX:-10
                  }
                },
                itemStyle: {
                  normal: {
                      shadowColor: 'rgba(0, 123, 237, 0.7)',
                      shadowBlur: 10,
                      shadowOffsetX:-10
                  },
                },
              },
              // {
              //   name: '油田',
              //   type: 'pie',
              //   radius: ['0%', '70%'],

              //   label: {
              //       show: false,
              //       position: 'center'
              //   },
              //   data: seriesDataArray,
              //   center: ["55%", "30%"],
              //   emphasis: {
              //     itemStyle: {
              //       shadowColor: 'rgba(0, 123, 237, 0.7)',
              //       shadowBlur: 50,
              //       shadowOffsetX:-10
              //     }
              //   },
              //   // itemStyle: {
              //   //   normal: {
              //   //       shadowColor: 'rgba(0, 123, 237, 0.7)',
              //   //       shadowBlur: 50,
              //   //       shadowOffsetX:-10
              //   //   },
              //   // },
              // }
            ]
          };


        myChart.setOption(option)

        })


    })


    return (
        <div id={props.id} style={{ width: props.width, height: props.height }}></div>
    );

}


export default Dash2PieChart
