import './oil_well_detail.css'
import * as echarts from 'echarts/core';
import { Form, Input, InputNumber, message} from 'antd';
import React, { useEffect, useState } from 'react';
import Request from '../../../request';
import { COLOR_LIST, COLOR_REVERSE_LIST } from '../../../config';



const App = (props: any) => {
  console.log('props=', props)

  const [statusMap, _] = useState({0: "未运行", 1: "运行中", 2: "断网", 3: "异常"})

  let request = new Request({});
  const [data, setData] = useState<any>({});

  const [period, setPeriod] = useState("近三月")

  const fetchData = (id: string) => {
    request.get('/api/v1/admin/oilwells/' + id + '/').then((res: any)=>{
      if(res.code === 0){
        console.log('res=', res.data)
        setData(res.data)
      }
    })
  }

  const fetchReportData = (id: string) => {
    request.get('/api/v1/admin/report/record/?category=month&shortcut=' + period + '&oil_well_id=' + id).then((res: any)=>{

      var xAxisDataArray = []
        var seriesDataArray = []

        for(var i=0; i<res.data.length; i++){
          let  series = {
            value: res.data[i].processed_oil,
          }
          xAxisDataArray.push(res.data[i].date)
          seriesDataArray.push(series)
        }

        let myChart = echarts.init(document.getElementById('oil-well-detail-report-chart') as HTMLElement, {renderer: 'svg'}, {devicePixelRatio: 2.5})

        let option = {
          xAxis: {
            type: 'category',
            data: xAxisDataArray
          },
          yAxis: {
            type: 'value'
          },

          tooltip: {
            show: true
          },
          series: [
            {
              data: seriesDataArray,
              type: 'bar',
              barWidth: seriesDataArray.length < 20 ? 20 : 0,
            }
          ]
        };
          
        myChart.setOption(option)
      })
  }

  const fetchFlowData = (id: string) => {
    request.get('/api/v1/admin/oilwells/' + id + '/height_logs/', {'params': {'start_time': '2023-10-01 08:00', 'end_time': '2023-10-17 08:00'}}).then((res: any)=>{

      var xAxisDataArray = []
        var seriesDataArray = []

        for(var i=0; i<res.data.length; i++){
          let  series = {
            value: res.data[i].oil_height,
          }
          xAxisDataArray.push(res.data[i].add_time)
          seriesDataArray.push(series)
        }


        let myChart = echarts.init(document.getElementById('oil-well-detail-flow-chart') as HTMLElement, {renderer: 'svg'}, {devicePixelRatio: 2.5})

        let option = {
          xAxis: {
            type: 'category',
            data: xAxisDataArray
          },
          yAxis: {
            type: 'value'
          },

          tooltip: {
            show: true
          },
          series: [
            {
              data: seriesDataArray,
              type: 'line',
              barWidth: seriesDataArray.length < 20 ? 20 : 0,
            }
          ]
        };

        myChart.setOption(option)
      })
  }

  useEffect(()=>{
    fetchData(props.id)
    fetchReportData(props.id)
    fetchFlowData(props.id)
  }, [period])

  const choosePeriod = (e: any) => {
    console.log(e.target.dataset.period)
    setPeriod(e.target.dataset.period)
  }

  return (
    <div className="oil-well-detail-container">
      <div className="oil-well-detail-header">
        <div>ID:{data.id}</div>
        <div className="oil-well-detail-info-list">
          <div className="oil-well-detail-info-item">名称:{data.name}</div>
          <div className="oil-well-detail-info-item">隶属油田:{data.field__name}</div>
          <div className="oil-well-detail-info-item">省市区:{data.province}-{data.city}-{data.district}</div>
          <div className="oil-well-detail-info-item">详细地址:{data.lbs_address}{data.floor ? '-' + data.floor: ""}</div>
          <div className="oil-well-detail-info-item">创建时间:{data.add_time}</div>
          <div className="oil-well-detail-info-item">状态:{statusMap[data.status]}</div>
          <div className="oil-well-detail-info-item">IMEI:{data.imei}</div>
          <div className="oil-well-detail-info-item">预计收油时间:{data.estimated_time}</div>
          <div className="oil-well-detail-info-item">箱体规格:{data.oil_model}[{data.oil_length}-{data.oil_width}-{data.oil_height}]</div>
          <div className="oil-well-detail-info-item">工作时间:{data.open_time}-{data.close_time}</div>
          <div className="oil-well-detail-info-item">温度上下限:{data.temp_min}-{data.temp_max}</div>
          <div className="oil-well-detail-info-item">当前油量:{data.current_oil}</div>
        </div>
      </div>
      <div className="oil-well-detail-report">
        <div className="oil-well-detail-report-period">
          <div className="oil-well-detail-report-peroid-list">
            <div className={period === "近三月" ?"oil-well-detail-report-peroid-item oil-well-detail-report-period-item-right oil-well-detail-report-period-item-selected":"oil-well-detail-report-peroid-item oil-well-detail-report-period-item-right"} onClick={choosePeriod} data-period="近三月">近三月</div>
            <div className={period === "近半年" ?"oil-well-detail-report-peroid-item oil-well-detail-report-period-item-right oil-well-detail-report-period-item-selected":"oil-well-detail-report-peroid-item oil-well-detail-report-period-item-right"} onClick={choosePeriod} data-period="近半年">近半年</div>
            <div className={period === "近一年" ?"oil-well-detail-report-peroid-item oil-well-detail-report-period-item-selected":"oil-well-detail-report-peroid-item"} onClick={choosePeriod} data-period="近一年">近一年</div>
          </div>
        </div>
        <div id="oil-well-detail-report-chart"></div>
      </div>
      <div id="oil-well-detail-flow-chart">33333</div>

    </div>
  );
};

export default App;