import './device_detail.css'
import { Form, Table} from 'antd';
import { useEffect, useState } from 'react';
import Request from '../../../request';
import {timestampToTime} from '../../../utils/date'



const App = (props: any) => {
  console.log('props=', props)

  let request = new Request({});
  const [data, setData] = useState<any>({});
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(0)
  const [pageSize, _] = useState(10)
  const [tableData, setTableData] = useState<any[]>([]);

  const fetchData = (id: string) => {
    request.get('/api/v1/admin/devices/' + id + '/').then((res: any)=>{
      if(res.code === 0){
        console.log('res=', res.data)
        setData(res.data)
        fetchTableData(1, res.data.imei)
      }
    })
  }

  const fetchTableData = (page: number, imei: string) => {
    request.get('/api/v1/admin/iots/device_logs/?imei=' + imei + '&limit=' + pageSize + '&page=' + page).then((res: any)=>{
      console.log("tableData===", res)

      if(res.code === 0){
        setTotal(res.total)
        var originData = []
        for(var i=0; i<res.data.length; i++){
           var tmp = res.data[i]
           tmp["key"] = res.data[i].id
           tmp['decrypt_content']['tanktemp'] = res.data[i]['decrypt_content']['tanktemp'] - 20  // 因为处理负数的问题，所以虚高20摄氏度
           tmp['decrypt_content']['nowtime'] = timestampToTime(res.data[i]['decrypt_content']['nowtime'])
           tmp['status'] = res.data[i]['status'] ? "已处理": "未处理"
           tmp['deal_note'] = res.data[i]['deal_note'] ? res.data[i]['deal_note']: "上报正常"
           originData.push(tmp)
        }
        setTableData(originData)
      }
    })
  }

  const columns = [
    {
      title: 'IMEI',
      dataIndex: 'imei',
      width: '20%',
      editable: true,
    },
    {
      title: '上报时间',
      dataIndex: ['decrypt_content', 'nowtime'],
      width: '18%',
      editable: false,
    },
    {
      title: '当前温度',
      dataIndex: ['decrypt_content', 'tanktemp'],
      width: '10%',
      editable: true,
    },
    {
      title: '加热状态',
      dataIndex: ['decrypt_content', 'heating'],
      width: '10%',
      editable: true,
    },
    {
      title: '锁状态',
      dataIndex: ['decrypt_content', 'lockstate'],
      width: '10%',
      editable: true,
    },
    {
      title: '上报说明',
      dataIndex: 'deal_note',
      width: '15%',
      editable: true,
    },
    {
      title: '处理结果',
      dataIndex: 'status',
      width: '10%',
      editable: true,
    },
  ]

  useEffect(()=>{
    fetchData(props.id)
  }, [])

  const changePage = (page: any) => {
    console.log('changePage', page)
    setCurrent(page)
    fetchTableData(page, data.imei)
  }

  return (
    <div className="device-detail-container">
      <div className="device-detail-header">
        <div>设备详情</div>
        <div className="device-detail-info-list">
          <div className="device-detail-info-item">ID:{data.id}</div>
          <div className="device-detail-info-item">名称:{data.device_id}</div>
          <div className="device-detail-info-item">创建时间:{data.add_time}</div>
          <div className="device-detail-info-item">IMEI:{data.imei}</div>
          <div className="device-detail-info-item">关联油井:{data.oil_well_name}</div>
          <div className="device-detail-info-item">当前版本:{data.version}</div>
        </div>
      </div>
      <div className="device-detail-history-log">
        <div className="device-detail-history-log-title">上报日志</div>
        <Form form={form} component={false}>
          <Table
            bordered
            dataSource={tableData}
            columns={columns}
            pagination={{
              onChange: changePage,
              total: total,
              current: current,
              pageSize: pageSize
            }}
          />
        </Form>
      </div>

    </div>
  );
};

export default App;