import './repair_list.css'

import { Form, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import Request from '../../../request';
import { Link } from 'react-router-dom';

interface Item {
  key: string;
  name: string;
  add_time: string;
  address: string;
}

const App: React.FC = () => {

  let request = new Request({});

  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(0)
  const [pageSize, _] = useState(10)


  const [form] = Form.useForm();
  const [data, setData] = useState<any[]>([]);
  const [editingKey, setEditingKey] = useState('');

  const fetchData = (page: number) => {
    request.get('/api/v1/admin/repair/?limit=' + pageSize + '&page=' + page).then((res: any)=>{
      console.log("res===", res)

      if(res.code === 0){
        setTotal(res.count)
        var originData = []
        for(var i=0; i<res.data.length; i++){
           var tmp = res.data[i]
           tmp["key"] = res.data[i].id
           originData.push(tmp)
        }
        setData(originData)
      }
    })
  }

  useEffect(()=>{
    fetchData(1)
  }, [])

  const columns = [
    {
      title: '标题',
      dataIndex: 'title',
      width: '30%',
      editable: true,
    },
    {
      title: '创建时间',
      dataIndex: 'add_time',
      width: '20%',
      editable: false,
    },
    {
      title: '创建用户',
      dataIndex: 'applicant__username',
      width: '15%',
      editable: true,
    },
    {
      title: '油井名称',
      dataIndex: 'oil_well_name',
      width: '20%',
      editable: true,
    },  
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_: any, record: Item) => {
        return (
          <div>
            <Link target="_blank" to={"/repair/detail/" + record.key}>详情</Link>           
          </div>
        )
      },
    },
  ];

  const changePage = (page: any) => {
    console.log('changePage', page)
    setCurrent(page)
    fetchData(page)
  }

  return (
    <Form form={form} component={false}>
      <Table
        bordered
        dataSource={data}
        columns={columns}
        pagination={{
          onChange: changePage,
          total: total,
          current: current,
          pageSize: pageSize
        }}
      />
    </Form>
  );
};

export default App;