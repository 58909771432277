import './device_edit.css'

import { Button, Form, Input, message} from 'antd';
import React, { useEffect, useState } from 'react';
import Request from '../../../request';
import { useNavigate } from 'react-router';


const App = (props: any) => {

  let request = new Request({});
  const [data, setData] = useState<any>({});
  const navigate = useNavigate();

  const fetchData = (id: string) => {
    request.get('/api/v1/admin/oilfields/' + id + '/').then((res: any)=>{
      if(res.code === 0){
        console.log('res=', res.data)
        setData(res.data)
      }
    })
  }

  useEffect(()=>{
    console.log('props=', props)
    if(props.type === 'edit'){
      fetchData(props.id)
    }
  }, [])

  const onFinish = (values: any) => {
      console.log('values=', values)
      request.post('/api/v1/admin/devices/', values).then((res: any)=>{
        if(res.code === 0){
          console.log('res=', res.data)
          message.info("增加设备成功")
          setTimeout(()=>{
            navigate('/device/list')
          }, 3000)
        }else{
          message.info(res.msg)
        }
      })
  }

  return (
    <div className="device-form-container">
      <Form className="device-form"
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item
          label="硬件ID"
          name="device_id"
          rules={[{ required: true, message: '请输入硬件ID' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="IMEI"
          name="imei"
          rules={[{ required: true, message: '请输入IMEI' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default App;