import React, { Component, useEffect, useState } from 'react'
import * as echarts from 'echarts/core';
import cookie from 'react-cookies'

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from 'echarts/components';
import { PieChart, BarChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import Request from '../../request';
import { COLOR_LIST, COLOR_REVERSE_LIST } from '../../config';


echarts.use([
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    PieChart,
    BarChart,
    CanvasRenderer,
    LabelLayout,
    GridComponent
  ]);


 function  MyBarChart(props: any) {

    const [oilFieldTopToday, setOilFieldTopToday] = useState({
      collect_record_count: 0,
      storage_record_count: 0,
      uncollect_record_count: 0,
      collected_record_count: 0,
      unstorage_record_count: 0,
      storaged_record_count: 0
    })
    // dashboard 今日油田油量分布
    useEffect(()=>{
        let user = cookie.load('user')
        if(!user){
          return
        }

        let myChart_exist = echarts.getInstanceByDom(document.getElementById(props.id) as HTMLElement);
        if (myChart_exist != undefined) {
            return
        }

        let request = new Request({});
        request.get('/api/v1/report/oil_well_top_today/?top=8').then((res: any)=>{

          var yAxisDataArray:any = []
          var seriesDataArray:any = []

          for(var i=0; i<res.data.length; i++){
            let yAxis = {
              value: res.data[i].name,
              textStyle: {
                color: COLOR_REVERSE_LIST[i],
                padding:20,
                fontSize: 12
              },
            }

            yAxisDataArray.push(yAxis)

           let  series = {
              value: res.data[i].number,
              itemStyle: {
                color: COLOR_REVERSE_LIST[i]
              }
            }
            seriesDataArray.push(series)
          }

          let myChart = echarts.init(document.getElementById(props.id) as HTMLElement, {renderer: 'svg'}, {devicePixelRatio: 2.5})

          let option = {
            animation:true,
            grid: {
              // left:80,
              // top: 30,
              // right: 16,
              // bottom: 30,
              left: 0,
              top: 40,
              right: 19,
              bottom: 40,
              containLabel: true,
            },

            yAxis: [
              // {
              //   type: 'category',
              //   show:true,
              //   splitLine: {
              //     show: false
              //   },
              //   axisLine: {show:false},//不显示坐标轴
              //   axisTick:{
              //     show:false,//不显示坐标轴刻度线
              //   },
              //   axisLabel:{
              //     interval: 0,
              //     // formatter:function(value:any){
              //     //   // if(value.indexOf('-') == -1){
              //     //   //   return value.replace("-","\n-\n")
              //     //   // }else if(value.indexOf("w") != -1){
              //     //   //   return value.replace("w","\nw")
              //     //   // }
              //     //   var ret = "";//拼接加\n返回的类目项
              //     //   var maxLength = 5;//每项显示文字个数
              //     //   var valLength = value.length;//X轴类目项的文字个数
              //     //   var rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
              //     //   if (rowN > 1)//如果类目项的文字大于5,
              //     //   {
              //     //       for (var i = 0; i < rowN; i++) {
              //     //           var temp = "";//每次截取的字符串
              //     //           var start = i * maxLength;//开始截取的位置
              //     //           var end = start + maxLength;//结束截取的位置
              //     //       //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
              //     //           temp = value.substring(start, end) + "\n";
              //     //           ret += temp; //凭借最终的字符串
              //     //       }
              //     //       return ret;
              //     //   }
              //     //   else {
              //     //       return value;
              //     //   }
              //     // }
              //   },
              //   data: yAxisDataArray.reverse(),
              // },
              {
                gridIndex: 0,
                type: 'category',
                // inverse: true,
                position: 'right',
                data: yAxisDataArray.reverse(),
                axisTick: { show: false },
                axisLine: { show: false },
                splitLine: { show: false },
                axisLabel: {
                   show:false,
                   width: 80,
                   padding: [0, 0, 40, 0],
                 //   align: 'left',
                },
             },
             {
                gridIndex: 0,
                type: 'category',
                inverse: true,
                data: yAxisDataArray.reverse(),
                axisTick: { show: false },
                axisLine: { show: false },
                splitLine: { show: false },
                position: 'right',
                axisLabel: {
                 //   width: 80,
                   padding: [0, 0, 40, 0],
                   align: 'left',
                   fontSize: 10,
                   formatter: function(name:any, index:any){
                      return `{value|${name}}`;
                      // return `{value|${name}   ${seriesDataArray[index].value}}`;
                   },
                   rich: {
                      value: {
                       //   color: '#fff',
                       //   fontSize: 12,
                         fontWeight: 500,
                      },
                   },
                },
             },
            ],
            xAxis: {
              type: 'value',
              show:false,
              axisLine: {show:false},//不显示坐标轴
              axisTick:{
                show:false,//不显示坐标轴刻度线
              },
              axisLabel: {
                interval: 0
              },
            },

            series: [
              {
                data: seriesDataArray.reverse(),
                type: 'bar',
                barWidth: 10,
                barGap: 20,
                barCategoryGap: 30,
                label: {
                  show: true,
                  distance:5,
                  position: 'right',
                  color:"#069EE2",
                  fontSize: 10,
                }
              }
            ]
          };

          myChart.setOption(option)

        })


    }, [])


    return (
        <div id={props.id} style={{ width: props.width, height: props.height }}></div>
    );

}


export default MyBarChart
