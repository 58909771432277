import moment from "moment"
import { useEffect, useState } from "react"

function  Clock(props: any) {
  
    const [time, setTime] = useState('');

    useEffect(() => {
      setInterval(() => {
        var tmp_time = moment(new Date()).format("YYYY/M/D HH:mm:ss") 
        setTime(tmp_time);
      }, 1000);
    }, []);
  
    return (
        <div>{time}</div>
    )
}


export default Clock