import './device_list.css'

import { Button, Form, Input, InputNumber, message, Popconfirm, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import Request from '../../../request';
import { Link } from 'react-router-dom';

interface Item {
  key: string;
  name: string;
  add_time: string;
  address: string;
}


interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: 'number' | 'text';
  record: Item;
  index: number;
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const App: React.FC = () => {

  let request = new Request({});

  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(0)
  const [pageSize, _] = useState(10)


  const [form] = Form.useForm();
  const [data, setData] = useState<any[]>([]);
  const [editingKey, setEditingKey] = useState('');

  const fetchData = (page: number) => {
    request.get('/api/v1/admin/devices/?limit=' + pageSize + '&page=' + page).then((res: any)=>{
      console.log("res===", res)

      if(res.code === 0){
        setTotal(res.count)
        var originData = []
        for(var i=0; i<res.data.length; i++){
           var tmp = res.data[i]
           tmp["key"] = res.data[i].id
           originData.push(tmp)
        }
        setData(originData)
      }
    })
  }

  useEffect(()=>{
    fetchData(1)
  }, [])



  const isEditing = (record: Item) => record.key === editingKey;

  const edit = (record: Partial<Item> & { key: React.Key }) => {
    form.setFieldsValue({ name: '', age: '', address: '', ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as Item;

      const newData = [...data];
      const index = newData.findIndex(item => key === item.key);
      if (index > -1) {
        console.log('row==', row)
        console.log('key==', key)
        request.put('/api/v1/admin/companies/' + key + '/', row).then((res: any)=>{
          
          console.log("res===", res)
          if(res.code === 0){
            var originData = []
            for(var i=0; i<res.data.length; i++){
               var tmp = res.data[i]
               tmp["key"] = res.data[i].id
               originData.push(tmp)
            }
          }
        })
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setData(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: '名称',
      dataIndex: 'device_id',
      width: '25%',
      editable: true,
    },
    {
      title: '创建时间',
      dataIndex: 'add_time',
      width: '20%',
      editable: false,
    },
    {
      title: 'IMEI',
      dataIndex: 'imei',
      width: '18%',
      editable: true,
    },
    {
      title: '油井名称',
      dataIndex: 'oil_well_name',
      width: '20%',
      editable: true,
    },  
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        return (
          <div>
            <Link target="_blank" to={"/device/detail/" + record.key}>详情</Link>
            &nbsp;&nbsp;
            <Popconfirm title="确定删除吗?" onConfirm={() => deleteItem(record.key)} style={{ marginRight: 8 }}>
              <a>删除</a>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const changePage = (page: any) => {
    console.log('changePage', page)
    setCurrent(page)
    fetchData(page)
  }

  const deleteItem = async (key: React.Key) => {
    console.log('deleteItem=', key)
    request.delete('/api/v1/admin/devices/' + key + '/').then((res: any)=>{
      if(res.code === 0){
        message.info('删除成功');
        fetchData(current)
      }else{
        message.info(res.msg);
      }
    })
  };

  const addDevice = () => {
    const w = window.open('/device/add', '_blank');
    if(w){ w.focus()}  
  }

  return (
    <div>
      <Button type="primary" className='oil-field-add-btn' onClick={addDevice}>+增加设备</Button>
      <Form form={form} component={false}>
        <Table
          bordered
          dataSource={data}
          columns={columns}
          rowClassName="editable-row"
          pagination={{
            onChange: changePage,
            total: total,
            current: current,
            pageSize: pageSize
          }}
        />
      </Form>
    </div>

  );
};

export default App;