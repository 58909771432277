import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group',
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem('Dashboard', 'sub1', <MailOutlined />, [
    getItem('概览', '0'),
    getItem('大屏一', '1'),
    getItem('大屏二', '2'),
  ]),
  getItem('资产管理', 'sub2', <SettingOutlined />, [
    getItem('油田列表', '3'),
    getItem('油井列表', '4'),
    getItem('设备列表', '5'),
  ]),
  getItem('油单管理', 'sub6', <SettingOutlined />, [
    getItem('收油单', '9'),
    getItem('收油记录', '10'),
    getItem('储油单', '11'),
  ]),
  getItem('公司管理', 'sub3', <AppstoreOutlined />, [
    getItem('公司列表', '6'),
    // getItem('Submenu', 'sub3', null, [getItem('Option 7', '7'), getItem('Option 8', '8')]),
  ]),
  getItem('用户管理', 'sub4', <SettingOutlined />, [
    getItem('用户列表', '7'),
  ]),
  getItem('报修', 'sub5', <SettingOutlined />, [
    getItem('报修列表', '8'),
  ]),
  getItem('升级管理', 'sub7', <SettingOutlined />, [
    getItem('升级包列表', '12'),
    getItem('升级任务列表', '13')
  ]),
];

// submenu keys of first level
const rootSubmenuKeys = ['sub1x',];

const App: React.FC = () => {
  const [openKeys, setOpenKeys] = useState(['sub1']);
  const navigate = useNavigate();

  const onOpenChange: MenuProps['onOpenChange'] = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey!) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const clickOption: MenuProps['onClick'] = e => {
    console.log('click ', e);
    if(e.key == '0'){
      navigate('/overview')
    }
    else if(e.key == '1'){
      const w = window.open('/dashboard', '_blank');
      if(w){ w.focus()}
    }else if(e.key == '2'){
      const w = window.open('/dashboard2', '_blank');
      if(w){ w.focus()}
    }else if(e.key === '3'){
      navigate('/oil_field/list')
    }else if(e.key === '4'){
      navigate('/oil_well/list')
    }else if(e.key === '5'){
      navigate('/device/list')
    }else if(e.key === '6'){
      navigate('/company/list')
    }else if(e.key === '7'){
      navigate('/user/list')
    }else if(e.key === '8'){
      navigate('/repair/list')
    }else if(e.key === '9'){
      navigate('/collect_bill/list')
    }else if(e.key === '10'){
      navigate('/collect_record/list')
    }else if(e.key === '11'){
      navigate('/storage_record/list')
    }else if(e.key === '12'){
      console.log(e.key,'e.key')
      navigate('/upgrade/list')
    }else if(e.key === '13'){
      navigate('/upgrade_task/list')
    }
  };

  return (
    <Menu
      mode="inline"
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      style={{ width: 256 }}
      items={items}
      onClick={clickOption}
    />
  );
};

export default App;