import React, { useEffect } from 'react'
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from 'echarts/components';
import { PieChart, BarChart, LineChart, PictorialBarChart} from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import Request from '../../request';
import { companyUnitProcess } from '../../utils/companyProcess';



echarts.use([
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    PieChart,
    BarChart,
    LineChart,
    CanvasRenderer,
    LabelLayout,
    GridComponent,
    PictorialBarChart
  ]);


 function  MyLineChart(props: any) {


    useEffect(()=>{


        if(!props.oilWellId){
          return
        }
        let request = new Request({});

        let myChart:any = echarts.getInstanceByDom(document.getElementById(props.id) as HTMLElement);
        if (myChart === undefined) {
          myChart = echarts.init(document.getElementById(props.id) as HTMLElement, {renderer: 'svg'}, {devicePixelRatio: 2.5})
        }

        // request.get('/api/v1/report/oil_well/' + props.oilWellId + '/record_history').then((res: any)=>{
        // request.get('/api/v1/report/oil_well/33/record_history/?start_time=2022-01').then((res: any)=>{
          request.get('/api/v1/report/oil_well/' + props.oilWellId + '/record_history/').then((res: any)=>{
          let copyOilObj:any = companyUnitProcess(res.data.total_processed_oil)
          res.data.oilCopy = copyOilObj.number
          res.data.oilUnit = copyOilObj.companyUnit
          let copyWaterObj:any = companyUnitProcess(res.data.total_processed_water)
          res.data.waterCopy = copyWaterObj.number
          res.data.waterUnit = copyWaterObj.companyUnit
          props.setOilWellHistory({
            oil: res.data.total_processed_oil,
            water: res.data.total_processed_water,
            oilCopy: res.data.oilCopy,
            oilUnit: res.data.oilUnit,
            waterCopy: res.data.waterCopy,
            waterUnit: res.data.waterUnit,
          })


          var data = []
          var data1:any = []
          var data2:any = []

          let length = res.data.data.length > 12 ? res.data.data.length-12: 0

          for(var i=length; i<res.data.data.length; i++){
            data.push(res.data.data[i].month)
            data1.push(res.data.data[i].processed_oil)
            data2.push(res.data.data[i].processed_water)
          }

          function calMax(arr: number[]) {
            var max = Math.max.apply(null, arr); // 获取最大值方法
            var maxint = Math.ceil(max / 5); // 向上以5的倍数取整
            var maxval = maxint * 5 + 5; // 最终设置的最大值
            return maxval; // 输出最大值
          }
          // 获取最小值方法
          function calMin(arr: number[]) {
            var min = Math.min.apply(null, arr); // 获取最小值方法
            var minint = Math.floor(min / 1); // 向下以1的倍数取整
            var minval = minint * 1 - 5; // 最终设置的最小值
            if(minval < 0){
              minval = 0
            }
            return minval; // 输出最小值
          }

          var maxData1 = calMax(data1);
          var maxData2 = calMax(data2);
          var minData1 = calMin(data1);
          var minData2 = calMin(data2);

          const dataColorArr = ["rgba(0, 255, 223, 0.50)","rgba(0, 255, 222, 0.98)","rgba(0, 255, 223, 0.39)", "rgba(0, 255, 223, 0.20)", "rgb(12, 109, 122)"];
          const dataColorArr2 = ["rgba(32, 176, 255, 0.50)","rgba(32, 195, 255, 0.98)","rgba(32, 172, 255, 0.39)","rgba(32, 163, 255, 0.20)", "rgba(11, 83, 128)"];
          var colorOne = {
              type: "linear",
              x: 0,
              x2: 1,
              y: 0,
              y2: 0,
              colorStops: [
                  {
                      offset: 0,
                      color: dataColorArr[0],
                  },
                  {
                      offset: 0.5,
                      color: dataColorArr[1],
                  },
                  {
                      offset: 0.5,
                      color: dataColorArr[2],
                  },
                  {
                      offset: 1,
                      color: dataColorArr[3],
                  },
              ],
          };
          var colorTwo = {
              type: "linear",
              x: 0,
              x2: 1,
              y: 0,
              y2: 0,
              colorStops: [
                  {
                      offset: 0,
                      color: dataColorArr2[0],
                  },
                  {
                      offset: 0.5,
                      color: dataColorArr2[1],
                  },
                  {
                      offset: 0.5,
                      color: dataColorArr2[2],
                  },
                  {
                      offset: 1,
                      color: dataColorArr2[3],
                  },
              ],
          };
          let option = {
            tooltip: {
              trigger: 'item',
              formatter: function (params:any) {
                // trigger: 'axis',
                  // var str = params[0].name + ":";

                  // params.filter(function (item:any) {
                  //     if (item.componentSubType == "bar") {
                  //         str += "<br/>" + item.seriesName + "：" + item.value;
                  //     }
                  // });
                  var str = params.name + ":";
                  str += "<br/>油量：" + data1[params.dataIndex];
                  str += "<br/>水量：" + data2[params.dataIndex];
                  return str;
              },
            },
            xAxis: {
              type: 'category',
              data: data,
              axisTick: {
                show: false
              },
              axisLine: {
                lineStyle:{color:'#51EDFF'}
              },
              // axisLabel: {
              //   interval: 0
              // }
            },
            textStyle: {fontFamily: 'din_black_italic_font'},

            yAxis: [
              {
                type: 'value',
                axisLine:{
                  lineStyle: {color:'#02F6A4'},
                },
                min: minData1,
                max: maxData1,
                splitNumber: 5,
                interval: (maxData1 - minData1) / 5,

                splitLine: {
                  show:false,
                  lineStyle: {
                    color: "rgba(82, 47, 47, 1)"
                  }
                  }
                },
              {
                type: 'value',
                // name:'水量',
                axisLine:{
                  lineStyle:{color:'#20C4FF'},
                },
                min: minData2,
                // max: 10000,
                max: Number(maxData2),
                splitNumber: 5,
                interval: (Number(maxData2) - minData2) / 5,
                // interval: 1000,
                splitLine: {
                  show:false,
                  lineStyle: {
                    color: "#20416A"
                  }
                  }
              }
            ],
            series: [
              // {
              //   data: data1,
              //   type: 'bar',
              //   smooth: true,
              //   yAxisIndex: 0,
              //   color: '#20C4FF',
              //   barWidth: 20
              // },

              // {
              //   data: data2,
              //   type: 'bar',
              //   smooth: true,
              //   yAxisIndex: 1,
              //   color: '#02F6A4',
              //   barWidth: 20
              // },
              //中
                {
                  z: 1,
                  name: '油量',
                  type: "bar",
                  barWidth: 20,
                  barGap: "0%",
                  data: data1,
                  yAxisIndex:0,
                  itemStyle: {
                      normal: {
                        color: colorOne,
                          // color: {
                          //     x: 0,
                          //     y: 0,
                          //     x2: 0,
                          //     y2: 1,
                          //     type: 'linear',
                          //     global: false,
                          //     colorStops: [
                          //         {
                          //             offset: 0,
                          //             color: 'rgba(32, 196, 255, 1)',
                          //         },
                          //         {
                          //             offset: 1,
                          //             color: 'rgba(32, 160, 255, 0.12)',
                          //         },
                          //     ],
                          // },
                          //柱形图上方标题
                          label: {
                              show: true,
                              position: 'top',
                              offset:[0,-20],
                              textStyle: {
                                  color: 'rgba(0, 143, 135, 1)',
                                  fontSize: 12
                              }
                          },
                      }
                  },
              },
              //下
              // {
              //     z: 2,
              //     name: '油量',
              //     type: "pictorialBar",
              //     data: data1.map(item => item + 90),
              //     yAxisIndex:0,
              //     symbol: "diamond",
              //     symbolOffset: ["-75%", "-470%"],
              //     symbolSize: [20, 10],
              //     itemStyle: {
              //         normal: {
              //             color: colorOne,
              //             // color: {
              //             //     x: 0,
              //             //     y: 0,
              //             //     x2: 0,
              //             //     y2: 1,
              //             //     type: 'linear',
              //             //     global: false,
              //             //     colorStops: [
              //             //         {
              //             //             offset: 0,
              //             //             color: 'rgba(32, 196, 255, 1)',
              //             //         },
              //             //         {
              //             //             offset: 1,
              //             //             color: 'rgba(32, 160, 255, 0.12)',
              //             //         },
              //             //     ],
              //             // },
              //         },
              //     },
              //     tooltip: {
              //         show: false,
              //     },
              // },
              //上
              {
                  z: 3,
                  name: '油量',
                  type: "pictorialBar",
                  symbolPosition: "end",
                  data: data1,
                  yAxisIndex:0,
                  symbol: "diamond",
                  symbolOffset: ["-75%", "-59%"],
                  symbolSize: [20,6],
                  itemStyle: {
                      normal: {
                          borderWidth: 2,
                          // color: 'rgba(27, 162, 220, 0.9)'
                          // color: dataColorArr,
                          color: {
                              x: 0,
                              y: 0,
                              x2: 0,
                              y2: 1,
                              type: 'linear',
                              global: false,
                              colorStops: [
                                {
                                  offset: 0,
                                  color: 'rgba(0, 255, 222, 1)',
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(0, 143, 135, 1)',
                                },
                              ],
                          },
                      },
                  },
                  tooltip: {
                      show: false,
                  },
              },
              {
                  z: 1,
                  name: '水量',
                  type: "bar",
                  barWidth: 20,
                  barGap: "50%",
                  data: data2,
                  yAxisIndex:1,
                  itemStyle: {
                      normal: {
                        color: colorTwo,
                          // color: {
                          //     x: 0,
                          //     y: 0,
                          //     x2: 0,
                          //     y2: 1,
                          //     type: 'linear',
                          //     global: false,
                          //     colorStops: [
                          //         {
                          //             offset: 0,
                          //             color: 'rgba(0, 255, 222, 1)',
                          //         },
                          //         {
                          //             offset: 1,
                          //             color: 'rgba(0, 255, 223, 0)',
                          //         },
                          //     ],
                          // },
                          //柱形图上方标题
                          label: {
                              show: true,
                              position: 'top',
                              offset:[0,-10],
                              textStyle: {
                                  color: 'rgba(27, 162, 220, 1)',
                                  fontSize: 12
                              }
                          },
                      }
                  },
              },
              // {
              //     z: 2,
              //     // name: '蓝色',
              //     name: '水量',
              //     type: "pictorialBar",
              //     data: data2.map(item => item + 90),
              //     yAxisIndex:1,
              //     symbol: "diamond",
              //     symbolOffset: ["75%", "-470%"],
              //     symbolSize: [20, 10],
              //     itemStyle: {
              //         normal: {
              //             // color: color2
              //             color: {
              //                 x: 0,
              //                 y: 0,
              //                 x2: 0,
              //                 y2: 1,
              //                 type: 'linear',
              //                 global: false,
              //                 colorStops: [
              //                     {
              //                         offset: 0,
              //                         color: 'rgba(0, 255, 222, 1)',
              //                     },
              //                     {
              //                         offset: 1,
              //                         color: 'rgba(0, 255, 223, 0)',
              //                     },
              //                 ],
              //             },
              //         },
              //     },
              //     tooltip: {
              //         show: false,
              //     },
              // },
              {
                  z: 3,
                  // name: '蓝色',
                  name: '水量',
                  type: "pictorialBar",
                  symbolPosition: "end",
                  data: data2,
                  yAxisIndex:1,
                  symbol: "diamond",
                  symbolOffset: ["75%", "-59%"],
                  symbolSize: [20, 6],
                  // symbolSize: [barWidth - 4, (10 * (barWidth - 4)) / barWidth],
                  itemStyle: {
                      normal: {
                          borderWidth: 2,
                          // color: 'rgba(0, 143, 135, 1)'
                          color: {
                              x: 0,
                              y: 0,
                              x2: 0,
                              y2: 1,
                              type: 'linear',
                              global: false,
                              colorStops: [
                                {
                                  offset: 0,
                                  color: 'rgba(32, 196, 255, 1)',
                                },
                                {
                                    offset: 1,
                                    color: 'rgba(27, 162, 220, 1)',
                                },
                              ],
                          },
                      },
                  },
                  tooltip: {
                      show: false,
                  },
              },

            ]
          };
          if(myChart){
            setTimeout(function(){
              // let myChart:any = echarts.getInstanceByDom(document.getElementById(props.id) as HTMLElement);
              myChart.setOption(option)
            },100)
          }
        }).catch((err) => {
          console.log(err)
        })

    }, [props.oilWellId])


    return (
        <div id={props.id} style={{ width: props.width, height: props.height }}></div>
    );

}


export default MyLineChart
