import './storage_record_detail.css'
import { Form, Table} from 'antd';
import { useEffect, useState } from 'react';
import Request from '../../../request';
import {timestampToTime} from '../../../utils/date'



const App = (props: any) => {

  let request = new Request({});
  const [data, setData] = useState<any>({});
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(0)
  const [pageSize] = useState(10)
  const [tableData, setTableData] = useState<any[]>([]);
  const [statusMap] = useState({"1": "是", "2": "否"})


  const fetchData = (id: string) => {
    request.get('/api/v1/admin/storage_oil/' + id + '/').then((res: any)=>{
      if(res.code === 0){
        console.log('storage record detail res=', res.data)
        setData(res.data)
        // fetchTableData(1, res.data.oil_well_id)
      }
    })
  }

  const fetchTableData = (page: number, oil_well_id: number) => {
    request.get('/api/v1/admin/storage_record/?oil_well_id=' + oil_well_id + '&limit=' + pageSize + '&page=' + page).then((res: any)=>{
      console.log("tableData===", res)

      if(res.code === 0){
        setTotal(res.total)
        var originData = []
        for(var i=0; i<res.data.length; i++){
           var tmp = res.data[i]
           tmp["key"] = res.data[i].id
           tmp['deal_note'] = res.data[i]['deal_note'] ? res.data[i]['deal_note']: "上报正常"
           originData.push(tmp)
        }
        setTableData(originData)
      }
    })
  }

  const columns = [
    {
      title: '单号',
      dataIndex: 'bill_number',
      width: '20%',
      editable: true,
    },
    {
      title: '创建时间',
      dataIndex: 'oil_collect_time',
      width: '18%',
      editable: false,
    },
    {
      title: '油量',
      dataIndex: 'oil_collect',
      width: '10%',
      editable: true,
    },
    {
      title: '隶属油井',
      dataIndex: 'oil_well_name',
      width: '10%',
      editable: true,
    },
    {
      title: '花费时间',
      dataIndex: 'total_consumel_time',
      width: '10%',
      editable: true,
    },
  ]

  useEffect(()=>{
    fetchData(props.id)
  }, [])

  const changePage = (page: any) => {
    console.log('changePage', page)
    setCurrent(page)
    fetchTableData(page, data.oil_well_id)
  }

  return (
    <div className="storage_record-detail-container">
      <div className="storage_record-detail-header">
        <div>储油单详情</div>
        <div className="storage_record-detail-info-list">
          <div className="storage_record-detail-info-item">ID:{data.id}</div>
          <div className="storage_record-detail-info-item">单号:{data.number}</div>
          <div className="storage_record-detail-info-item">创建时间:{data.apply_time}</div>
          <div className="storage_record-detail-info-item">关联收油单:{data.bill_number}</div>
          <div className="storage_record-detail-info-item">油量:{data.oil_storage}L</div>
          <div className="storage_record-detail-info-item">收油员:{data.apply_man}</div>
          <div className="storage_record-detail-info-item">是否入库:{statusMap[data.status]}</div>
          {data.status == '1' ? <div className="storage_record-detail-info-item">储油员:{data.oil_storage_man}</div>: ""}
          {data.status == '1' ? <div className="storage_record-detail-info-item">实际油量:{data.oil_real}KG</div>: ""}
          
          
        </div>
      </div>
      {/* <div className="storage_record-detail-history-log">
        <div className="storage_record-detail-history-log-title">历史收油单</div>
        <Form form={form} component={false}>
          <Table
            bordered
            dataSource={tableData}
            columns={columns}
            pagination={{
              onChange: changePage,
              total: total,
              current: current,
              pageSize: pageSize
            }}
          />
        </Form>
      </div> */}

    </div>
  );
};

export default App;