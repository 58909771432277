import './collect_bill_list.css'

import { Form, Input, InputNumber, Popconfirm, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import Request from '../../../request';
import { Link } from 'react-router-dom';

interface Item {
  key: string;
  name: string;
  add_time: string;
  address: string;
}

const App: React.FC = () => {

  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(0)
  const [pageSize, _] = useState(10)

  let request = new Request({});

  const [form] = Form.useForm();
  const [data, setData] = useState<any[]>([]);
  const [editingKey, setEditingKey] = useState('');

  const fetchData = (page: number) => {
    request.get('/api/v1/admin/collect_bill/?limit=' + pageSize + '&page=' + page).then((res: any)=>{
      console.log("res===", res)

      if(res.code === 0){
        console.log('res.count=', res.count)
        setTotal(res.count)
        var originData = []
        for(var i=0; i<res.data.length; i++){
           var tmp = res.data[i]
           tmp["key"] = res.data[i].id
           if(res.data[i].is_collect === "1"){
            tmp["is_collect"] = "是"
           }else if(res.data[i].is_collect === "0"){
            tmp["is_collect"] = "否"
           }
           originData.push(tmp)
        }
        setData(originData)
      }
    })
  }

  useEffect(()=>{
    fetchData(1)
  }, [])

  const columns = [
    {
      title: '单号',
      dataIndex: 'number',
      width: '15%',
      editable: false,
    },
    {
      title: '创建时间',
      dataIndex: 'add_time',
      width: '18%',
      editable: false,
    },
    {
      title: '隶属油井',
      dataIndex: 'oil_well_name',
      width: '20%',
      editable: false,
    },
    {
      title: '油量（升）',
      dataIndex: 'oil_now',
      width: '12%',
      editable: false,
    },
    {
      title: '是否收油',
      dataIndex: 'is_collect',
      width: '9%',
      editable: false,
    },
    {
      title: '用时',
      dataIndex: 'total_consumel_time',
      width: '12%',
      editable: false,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_: any, record: Item) => {
        return (
          <Link target="_blank" to={"/collect_bill/detail/" + record.key}>详情</Link>
        );
      },
    },
  ];

  const changePage = (page: any) => {
    console.log('changePage', page)
    setCurrent(page)
    fetchData(page)
  }

  return (
    <Form form={form} component={false}>
      <Table
        bordered
        dataSource={data}
        columns={columns}
        pagination={{
          onChange: changePage,
          total: total,
          current: current,
          pageSize: pageSize
        }}
      />
    </Form>
  );
};

export default App;