import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './components/Login/login'
import UserLogin from './components/UserLogin/user_login'
import Dashboard from './components/Dashboard/dashboard'
import Dashboard2 from './components/Dashboard2/dashboard2'
import OilMap from './components/Admin/OilMap/oil_map'
import PageNotFound from './components/PageNotFound/page_not_found'
// import 'lib-flexible';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(

  <BrowserRouter>
    <Routes>
      <Route path="/login" element={<Login /> }></Route>
      <Route path="/user/login" element={<UserLogin /> }></Route>
      <Route path="/admin" element={<App menu='overview' /> }></Route>
      <Route path="/company/list" element={<App menu="company_list"/> }></Route>
      <Route path="/company/detail/:id" element={<App menu="company_detail"/> }></Route>
      <Route path="/user/list" element={<App menu="user_list"/> }></Route>
      <Route path="/user/detail/:id" element={<App menu="user_detail"/> }></Route>
      <Route path="/oil_field/list" element={<App menu="oil_field_list"/> }></Route>
      <Route path="/oil_field/add" element={<App menu="oil_field_add"/> }></Route>
      <Route path="/oil_field/edit/:id" element={<App menu="oil_field_edit"/> }></Route>
      <Route path="/oil_field/detail/:id" element={<App menu="oil_field_detail"/> }></Route>
      <Route path="/oil_well/list" element={<App menu="oil_well_list"/> }></Route>
      <Route path="/oil_well/detail/:id" element={<App menu="oil_well_detail"/> }></Route>
      <Route path="/device/list" element={<App menu="device_list"/> }></Route>
      <Route path="/device/detail/:id" element={<App menu="device_detail"/> }></Route>
      <Route path="/device/add" element={<App menu="device_add"/> }></Route>
      <Route path="/device/edit/:id" element={<App menu="device_edit"/> }></Route>
      <Route path="/repair/list" element={<App menu="repair_list"/> }></Route>
      <Route path="/repair/detail/:id" element={<App menu="repair_detail"/> }></Route>
      <Route path="/collect_bill/list" element={<App menu="collect_bill_list"/> }></Route>
      <Route path="/collect_bill/detail/:id" element={<App menu="collect_bill_detail"/> }></Route>
      <Route path="/collect_record/list" element={<App menu="collect_record_list"/> }></Route>
      <Route path="/collect_record/detail/:id" element={<App menu="collect_record_detail"/> }></Route>
      <Route path="/storage_record/list" element={<App menu="storage_record_list"/> }></Route>
      <Route path="/storage_record/detail/:id" element={<App menu="storage_record_detail"/> }></Route>
      <Route path="/dashboard" element={<Dashboard /> }></Route>
      <Route path="/dashboard2" element={<Dashboard2 /> }></Route>
      <Route path="/overview" element={<App menu='overview' /> }></Route>
      <Route path="/upgrade/list" element={<App menu="upgrade_list"/> }></Route>
      <Route path="/upgrade_task/list" element={<App menu="upgrade_task_list"/> }></Route>
      <Route path="/upgrade_task/detail/:id" element={<App menu="upgrade_task_detail"/> }></Route>
      <Route path="/upgrade_task/add" element={<App menu="upgrade_task_add"/> }></Route>
      <Route path="/oil_map" element={<OilMap /> }></Route>
      <Route path="*" element={<PageNotFound /> }></Route>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
