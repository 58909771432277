import './collect_record_list.css'

import { Form, Input, InputNumber, Popconfirm, Table, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import Request from '../../../request';
import { Link } from 'react-router-dom';

interface Item {
  key: string;
  name: string;
  add_time: string;
  address: string;
}


const App: React.FC = () => {

  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(0)
  const [pageSize, _] = useState(10)

  let request = new Request({});

  const [form] = Form.useForm();
  const [data, setData] = useState<any[]>([]);
  const [editingKey, setEditingKey] = useState('');

  const fetchData = (page: number) => {
    request.get('/api/v1/admin/collect_record/?limit=' + pageSize + '&page=' + page).then((res: any)=>{
      console.log("res===", res)

      if(res.code === 0){
        console.log('res.count=', res.count)
        setTotal(res.count)
        var originData = []
        for(var i=0; i<res.data.length; i++){
           var tmp = res.data[i]
           tmp["key"] = res.data[i].id
           if(res.data[i].storage_status === "1"){
            tmp["storage_status"] = "是"
           }else if(res.data[i].storage_status === "2"){
            tmp["storage_status"] = "否"
           }
           originData.push(tmp)
        }
        setData(originData)
      }
    })
  }

  useEffect(()=>{
    fetchData(1)
  }, [])

  const columns = [
    {
      title: '单号',
      dataIndex: 'bill_number',
      width: '15%',
      editable: false,
    },
    {
      title: '创建时间',
      dataIndex: 'oil_collect_time',
      width: '18%',
      editable: false,
    },
    {
      title: '隶属油井',
      dataIndex: 'oil_well_name',
      width: '20%',
      editable: false,
    },
    {
      title: '油量',
      dataIndex: 'oil_now',
      width: '10%',
      editable: false,
    },
    {
      title: '收油员',
      dataIndex: 'oil_man',
      width: '10%',
      editable: false,
    },
    {
      title: '是否生成储油单',
      dataIndex: 'storage_status',
      width: '13%',
      editable: false,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_: any, record: Item) => {
        return (
          <Link target="_blank" to={"/collect_record/detail/" + record.key}>详情</Link>
        );
      },
    },
  ];

  const changePage = (page: any) => {
    console.log('changePage', page)
    setCurrent(page)
    fetchData(page)
  }

  return (
    <Form form={form} component={false}>
      <Table
        bordered
        dataSource={data}
        columns={columns}
        pagination={{
          onChange: changePage,
          total: total,
          current: current,
          pageSize: pageSize
        }}
      />
    </Form>
  );
};

export default App;