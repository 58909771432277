import './overview.css'

import { Button, Form, message, Popconfirm, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import cookie from 'react-cookies'
import Request from '../../../request';
import * as echarts from 'echarts/core';
import { COLOR_LIST, COLOR_REVERSE_LIST } from '../../..//config';


const App: React.FC = () => {
  let request = new Request({});

  const [overviewData, setOverviewData] = useState<any>({});


  const overview = () => {
    request.get('/api/v1/admin/report/summary/').then((res: any)=>{
      console.log("overview=", res.data)
      setOverviewData({...res.data})
    })
  }

  const oilFieldChart = () => {
    let oilFieldChart_exist = echarts.getInstanceByDom(document.getElementById("overview-top-item-chart-oilfield") as HTMLElement);
    if (oilFieldChart_exist !== undefined) {
        return
    }

    let oilFieldChart = echarts.init(document.getElementById("overview-top-item-chart-oilfield") as HTMLElement, {renderer: 'svg'}, {devicePixelRatio: 2.5})


    request.get('/api/v1/admin/report/top/?shortcut=近一年&category=field&limit=5').then((res: any)=>{

      console.log("res=", res)

      var legendDataArray = []
      var seriesDataArray = []

      for(var i=0; i<res.data.length; i++){
        let legend = {
          name: res.data[i].name,
          textStyle: {
              color: COLOR_REVERSE_LIST[i]
          }
        }

        legendDataArray.push(legend)

       let  series = { 
          value: res.data[i].processed_oil, 
          name: res.data[i].name,
          textStyle: {
            color: COLOR_LIST[i]
          }
        }
        seriesDataArray.push(series)
      }

      let option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          icon: "rect",
          x: 'center',
          y: 'bottom',
          itemHeight: 10,
          itemWidth: 14,
          data: legendDataArray
        },

        color: COLOR_REVERSE_LIST,
        series: [
          {
            name: '油田',
            type: 'pie',
            radius: ['0%', '70%'],

            label: {
                show: false,
                position: 'center'
            },
            data: seriesDataArray,
            center: ["50%", "50%"],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      
      
    oilFieldChart.setOption(option)
    })
  }


  const oilWellChart = () => {
    let oilWellChart_exist = echarts.getInstanceByDom(document.getElementById("overview-top-item-chart-oilwell") as HTMLElement);
    if (oilWellChart_exist !== undefined) {
        return
    }

    let oilWellChart = echarts.init(document.getElementById("overview-top-item-chart-oilwell") as HTMLElement, {renderer: 'svg'}, {devicePixelRatio: 2.5})

    request.get('/api/v1/admin/report/top/?shortcut=近一年&category=well&limit=5').then((res: any)=>{

      console.log("res=", res)

      var legendDataArray = []
      var seriesDataArray = []

      for(var i=0; i<res.data.length; i++){
        let legend = {
          name: res.data[i].name,
          textStyle: {
              color: COLOR_REVERSE_LIST[i]
          }
        }

        legendDataArray.push(legend)

       let  series = { 
          value: res.data[i].processed_oil, 
          name: res.data[i].name,
          textStyle: {
            color: COLOR_LIST[i]
          }
        }
        seriesDataArray.push(series)
      }

      let option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          icon: "rect",
          x: 'center',
          y: 'bottom',
          itemHeight: 10,
          itemWidth: 14,
          data: legendDataArray
        },

        color: COLOR_REVERSE_LIST,
        series: [
          {
            name: '油井',
            type: 'pie',
            radius: ['0%', '70%'],

            label: {
                show: false,
                position: 'center'
            },
            data: seriesDataArray,
            center: ["50%", "50%"],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      
      
    oilWellChart.setOption(option)
    })
  }

  const companyChart = () => {
    let companyChart_exist = echarts.getInstanceByDom(document.getElementById("overview-top-item-chart-company") as HTMLElement);
    if (companyChart_exist !== undefined) {
        return
    }

    let companyChart = echarts.init(document.getElementById("overview-top-item-chart-company") as HTMLElement, {renderer: 'svg'}, {devicePixelRatio: 2.5})

    request.get('/api/v1/admin/report/top/?shortcut=近一年&category=company&limit=5').then((res: any)=>{

      console.log("res=", res)

      var legendDataArray = []
      var seriesDataArray = []

      for(var i=0; i<res.data.length; i++){
        let legend = {
          name: res.data[i].name,
          textStyle: {
              color: COLOR_REVERSE_LIST[i]
          }
        }

        legendDataArray.push(legend)

       let  series = { 
          value: res.data[i].processed_oil, 
          name: res.data[i].name,
          textStyle: {
            color: COLOR_LIST[i]
          }
        }
        seriesDataArray.push(series)
      }

      let option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          icon: "rect",
          x: 'center',
          y: 'bottom',
          itemHeight: 10,
          itemWidth: 14,
          data: legendDataArray
        },

        color: COLOR_REVERSE_LIST,
        series: [
          {
            name: '公司',
            type: 'pie',
            radius: ['0%', '70%'],

            label: {
                show: false,
                position: 'center'
            },
            data: seriesDataArray,
            center: ["50%", "50%"],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      
      
    companyChart.setOption(option)
    })
  }

  const provinceChart = () => {
    let provinceChart_exist = echarts.getInstanceByDom(document.getElementById("overview-top-item-chart-province") as HTMLElement);
    if (provinceChart_exist !== undefined) {
        return
    }

    let provinceChart = echarts.init(document.getElementById("overview-top-item-chart-province") as HTMLElement, {renderer: 'svg'}, {devicePixelRatio: 2.5})

    request.get('/api/v1/admin/report/top/?shortcut=近一年&category=province&limit=5').then((res: any)=>{

      console.log("res=", res)

      var legendDataArray = []
      var seriesDataArray = []

      for(var i=0; i<res.data.length; i++){
        let legend = {
          name: res.data[i].name,
          textStyle: {
              color: COLOR_REVERSE_LIST[i]
          }
        }

        legendDataArray.push(legend)

       let  series = { 
          value: res.data[i].processed_oil, 
          name: res.data[i].name,
          textStyle: {
            color: COLOR_LIST[i]
          }
        }
        seriesDataArray.push(series)
      }

      let option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          icon: "rect",
          x: 'center',
          y: 'bottom',
          itemHeight: 10,
          itemWidth: 14,
          data: legendDataArray
        },

        color: COLOR_REVERSE_LIST,
        series: [
          {
            name: '省份',
            type: 'pie',
            radius: ['0%', '70%'],

            label: {
                show: false,
                position: 'center'
            },
            data: seriesDataArray,
            center: ["50%", "50%"],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      };
      
      
    provinceChart.setOption(option)
    })
  }

  useEffect(()=>{
    if(!cookie.load('token')){
      return
    }
    overview()
    oilFieldChart()
    oilWellChart()
    companyChart()
    provinceChart()
  }, [])


  return (

    <div className='overview-container'>
      <div className="overview-header">
        <div className="overview-title">数据概览</div>
        <div className="overview-num-list">
          <div className="overview-num-item">
            <div className="overview-num-item-title">油田</div>
            <div className="overview-num-item-value">{overviewData.oil_field_count}块</div>
          </div>
          <div className="overview-num-item">
            <div className="overview-num-item-title">油井</div>
            <div className="overview-num-item-value">{overviewData.oil_well_count}口</div>
          </div>
          <div className="overview-num-item">
            <div className="overview-num-item-title">收油单</div>
            <div className="overview-num-item-value">{overviewData.oil_field_count}单</div>
          </div>
          <div className="overview-num-item">
            <div className="overview-num-item-title">公司</div>
            <div className="overview-num-item-value">{overviewData.company_count}家</div>
          </div>
          <div className="overview-num-item">
            <div className="overview-num-item-title">累计处理油量</div>
            <div className="overview-num-item-value">{overviewData.processed_oil}L({overviewData.processed_oil_weight}kg)</div>
          </div>
          <div className="overview-num-item">
            <div className="overview-num-item-title">累计处理水量</div>
            <div className="overview-num-item-value">{overviewData.processed_water}L</div>
          </div>
        </div>
      </div>


      <div className="overview-topn-list">
          <div className="overview-topn-item">
            <div className="overview-topn-item-title">油田TOP10</div>
            <div className="overview-topn-item-chart" id="overview-top-item-chart-oilfield" style={{width: '100%', height: '280px'}}></div>
          </div>
          <div className="overview-topn-item">
            <div className="overview-topn-item-title">油井TOP10</div>
            <div className="overview-topn-item-chart" id="overview-top-item-chart-oilwell" style={{width: '100%', height: '280px'}}></div>
          </div>
          <div className="overview-topn-item">
            <div className="overview-topn-item-title">公司TOP10</div>
            <div className="overview-topn-item-chart" id="overview-top-item-chart-company" style={{width: '100%', height: '280px'}}></div>
          </div>
          <div className="overview-topn-item">
            <div className="overview-topn-item-title">省份TOP10</div>
            <div className="overview-topn-item-chart" id="overview-top-item-chart-province" style={{width: '100%', height: '280px'}}></div>
          </div>
      </div>

    </div>

  );
};

export default App;


