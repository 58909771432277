import { Input } from 'antd'
import './user_login.css'

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'

import cookie from 'react-cookies'
import Request from '../../request';


export default function Login(){

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

    useEffect(()=>{
        let user = cookie.load('user')

        if(user){
            navigate('/dashboard')
        }
    }, [])


    
    function changeUsername(e: any){
        setUsername(e.target.value)
    }

    function changePassword(e: any){
        setPassword(e.target.value)
    }

    function checkInput(){
        if (!username || !password){
            return '用户名或密码为空'
        }
    }

    // {"username":"admin","mobile":null,"employee_number":null,"company_name":"","company_id":"","is_superuser":true,"token":"eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxLCJ1c2VybmFtZSI6ImFkbWluIiwiZXhwIjoxNjU5NTIxMzc3LCJlbWFpbCI6IjEyM0BxcS5jb20ifQ.PykQ8zHJlXO7YQx_39d8Xxp0rBIQ5XnkB6wn-FJ0ncE","role":"1000","permission":["oilfield","oilwell","oil","repairbill","repairrecord","collectbill","collectrecord","storage","user","device","company"]}
    
    function submit(){
        let errMsg = checkInput()
        if(errMsg){
            alert(errMsg)
        }

        let request = new Request({});

        request.post('/api/v1/login/', {
            username: username,
            password: password
        }).then(function(response:any){
            // storage.set('user', response)
            cookie.save('user', response.data, { path: '/' })
            cookie.save('token', response.data.token, { path: '/' })
            navigate('/dashboard')
        })
    }

    return (
        <div className="login-bg">
            <div className='login-form'>
                <div className='login-title'>欢迎登录城油后台管理系统</div>
                <div className='login-item'>
                    <div className='login-item-key'>用户名：</div><Input className='login-input' onChange={changeUsername}></Input>
                </div>

                <div className='login-item'>
                <div className='login-item-key'>密码：</div><Input type='password' className='login-input' onChange={changePassword}></Input>
                </div>

                <div className='login-btn' onClick={ submit }>登录</div>
            </div>
        </div>
    )
}
