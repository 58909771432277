import React, { useEffect } from 'react'
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout
  ]);

  // 油井分布
 function  Dash2PieChart2(props: any) {
    useEffect(()=>{

        let myChart_exist = echarts.getInstanceByDom(document.getElementById(props.id) as HTMLElement);
        if (myChart_exist !== undefined) {
            myChart_exist.dispose()
        }

        let myChart = echarts.init(document.getElementById(props.id) as HTMLElement, {renderer: 'svg'}, {devicePixelRatio: 2.5})

        let option = {
            tooltip: {
              trigger: 'item'
            },
            legend: {
              orient: 'vertical',
              icon: "rect",
              x: 'left',
              y: 'center',
              itemHeight: 10,
              itemWidth: 14,
              data: [
                {
                  name: '已投产··········' + props.summary.make_oil_well_count,
                  textStyle: {
                      color: '#00FDDC'
                  }
                },
                {
                  name: '未投产··········' + props.summary.unmake_oil_well_count,
                  textStyle: {
                      color: '#FFD558'
                  }
                },
                {
                  name: '故障··········' + 0,
                  textStyle: {
                      color: '#005DC7'
                  }
                }      
              ]
            },

            color: ['#2EFFA6', '#1DCCFF', '#DC2C4B'],
            series: [
              {
                name: '油井',
                type: 'pie',
                radius: ['35%', '45%'],
                label: {
                    show: false,
                    position: 'center'
                },
                data: [
                  { 
                    value: props.summary.make_oil_well_count, 
                    name: '已投产··········' + props.summary.make_oil_well_count,
                    textStyle: {
                      color: '#2EFFA6'
                    }
                  },
                  { 
                    value: props.summary.unmake_oil_well_count, 
                    name: '未投产··········' + props.summary.unmake_oil_well_count,
                    textStyle: {
                      color: '#1DCCFF'
                    }
                  },
                  { 
                    value: 0, 
                    name: '故障··········' + 0,
                    textStyle: {
                      color: '#DC2C4B'
                    }
                  }
                ],
                center: ["70%", "45%"],
                emphasis: {
                  itemStyle: {
                    shadowBlur: 10,
                    shadowOffsetX: 0,
                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                  }
                }
              }
            ]
          };
          
          
        myChart.setOption(option)
    }, [props.summary])
 

    return (
        <div id={props.id} style={{ width: props.width, height: props.height }}></div>
    );

}


export default Dash2PieChart2
