import React, { Component, useEffect, useState } from 'react'
import * as echarts from 'echarts/core';
import cookie from 'react-cookies'

import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import Request from '../../request';
import { COLOR_LIST, COLOR_REVERSE_LIST } from '../../config';


echarts.use([
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    PieChart,
    CanvasRenderer,
    LabelLayout
  ]);


 function  MyPieChart(props: any) {
    //  console.log("props=", props);

    // const [legendData, setLegendData] = useState<any[]>([])
    // const [seriesData, setSeriesData] = useState<any[]>([])

    let request = new Request({});


    useEffect(()=>{
        let user = cookie.load('user')
        if(!user){
          return
        }
        
        let myChart_exist = echarts.getInstanceByDom(document.getElementById(props.id) as HTMLElement);
        if (myChart_exist != undefined) {
            return
        }

        request.get('/api/v1/report/oil_field_top_today/').then((res: any)=>{
          var legendDataArray = []
          var seriesDataArray = []

          for(var i=0; i<res.data.length; i++){
            let legend = {
              name: res.data[i].name,
              textStyle: {
                  color: COLOR_REVERSE_LIST[i]
              },
              itemStyle: {
                color: COLOR_REVERSE_LIST[i]
              }
            }

            legendDataArray.push(legend)

           let  series = {
              value: res.data[i].number,
              name: res.data[i].name,
              textStyle: {
                color: COLOR_REVERSE_LIST[i]
              },
              isActive: false
              // isActive: i == 0 ? true : false
            }
            seriesDataArray.push(series)
          }

          let myChart = echarts.init(document.getElementById(props.id) as HTMLElement, {renderer: 'svg'}, {devicePixelRatio: 2.5})

          let option = {
              tooltip: {
                trigger: 'item',
                // formatter: function(params: any) {
                //   console.log(params,'parameter')
                // }
              },
              legend: {
                icon: "rect",
                orient: 'vertical',
                x: 'left',
                y: 'bottom',
                padding: [0, 0, 18, -3],
                itemHeight: 8,
                itemWidth: 10,
                fontSize: 10,
                data: legendDataArray
              },

              color: COLOR_LIST.reverse(),
              series: [
                {
                  name: '今日油田油量',
                  type: 'pie',
                  radius: ['55%', '70%'],

                  // label: {
                  //     show: false,
                  //     position: 'center'
                  // },
                  label: {
                    show: true,
                    normal: {
                      position: 'outside',
                      fontSize: 10,
                      color: 'auto',
                      formatter: function(params:any) {
                        // console.log(params)
                        let color:any = params.color;
                        return params.value;
                        // return `${color}|${params.value}`;
                      }
                    }
                  },

                  labelLine: {
                    length: 5,
                    length2: 0,
                    smooth: true
                  },
                  data: seriesDataArray,
                  center: ["63%", "50%"],
                  emphasis: {
                    itemStyle: {
                      shadowBlur: 10,
                      shadowOffsetX: 0,
                      shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                  }
                },
                {
                  name: "今日油田油量",
                  type: "pie",
                  radius: ['51%', '52%'],
                  center: ["63%", "50%"], // 图形位置
                  itemStyle: {
                    color: 'rgba(255, 255, 255, 0.3)'
                  },
                  label: {
                    show: false,
                  },
                  emphasis: {
                    disabled: true,
                  },
                  data: seriesDataArray,
                },
                {
                  name: "今日油田油量",
                  type: "pie",
                  radius: [0, '50%'],
                  center: ["63%", "50%"], // 图形位置
                  itemStyle: {
                    color: (params: any) => {
                      // console.log(params,'params')
                      if (params.data.isActive) {
                        return "rgba(255, 255, 255, 0.3)";
                      } else {
                        return "rgba(0, 0, 0, 0)";
                      }
                    },
                  },
                  label: {
                    show: false,
                  },
                  emphasis: {
                    disabled: true,
                  },
                  data: seriesDataArray,
                },
              ]
            };

            myChart.setOption(option)
            myChart.on("mouseover", (params) => {
              if (params.seriesType === "pie" && params.seriesName === "今日油田油量") {
                const optionOne:any = myChart.getOption();
                 // 鼠标移入关闭默认展示第一项效果
                 optionOne.series[0].label.show = true;
                 optionOne.series[0].data[0].isActive = false;
                 optionOne.series[2].data[0].isActive = false;
                // 显示鼠标移入项的效果
                optionOne.series[0].data[params.dataIndex].isActive = true;
                optionOne.series[2].data[params.dataIndex].isActive = true;
                myChart.setOption(optionOne);
              }
            });
            myChart.on("mouseout", (params) => {
              if (params.seriesType === "pie" && params.seriesName === "今日油田油量") {
                // 鼠标离开每一项后关闭移入效果
                const optionTwo:any = myChart.getOption();
                optionTwo.series[0].data[params.dataIndex].isActive = false;
                optionTwo.series[2].data[params.dataIndex].isActive = false;
                myChart.setOption(optionTwo);
              } else if (params.componentType === "graphic") {
                // 鼠标离开echarts整体后展示默认效果,即默认选中第一项
                const optionThree:any = myChart.getOption();
                optionThree.series[0].label.show = true;
                optionThree.series[0].data[0].isActive = true;
                optionThree.series[2].data[0].isActive = true;
                myChart.setOption(optionThree);
              }
            });

        })

    }, [])


    return (
        <div id={props.id} style={{ width: props.width, height: props.height }}></div>
    );

}


export default MyPieChart
