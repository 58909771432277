export function companyUnitProcess(companyNumber) {
    let companyStr = companyNumber
    let obj = {
        number: 0,
        companyUnit: ''
    }

    // console.log(companyStr,'companyStr')
    // console.log(companyStr/1000,'companyStr/1000')
    // L  -- 0
    // T  -- 1T = 1000L --- 1000
    // KT  -- 10000000
    if(companyStr/1000000 > 1){
        // console.log(companyStr,1)
        obj.number = (companyStr/1000000)//KT
        obj.companyUnit = 'KT'
        return obj//KT
    }else if(9999 > companyStr/1000 && companyStr/1000 > 1){
        // console.log("zzzz", companyStr, Math.floor(companyStr/1000).toFixed(2))
        obj.number = (companyStr/1000)//KT
        obj.companyUnit = 'T'
        return obj//T
    }else if(999 > companyStr && companyStr > 1){
        // console.log(companyStr,3)
        obj.number = (companyStr)//L
        obj.companyUnit = 'L'
        return obj//L
    }else{
        return obj = {
            number: 0,
            companyUnit: 'L'
        }
    }
}