// import './upgrade_list.css'

import { Button, Form, message, Popconfirm, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import Request from '../../../request';
import { Link } from 'react-router-dom';

interface Item {
  key: string;
  name: string;
  add_time: string;
  is_active: boolean;
}

const App: React.FC = () => {

  let request = new Request({});

  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(0)
  const [pageSize, _] = useState(10)

  const [form] = Form.useForm();
  const [data, setData] = useState<any[]>([]);
  const [editingKey, setEditingKey] = useState('');

  const fetchData = (page: number) => {
    request.get('/api/v1/admin/iots/softwares/?limit=' + pageSize + '&page=' + page).then((res: any)=>{

      if(res.code === 0){
        setTotal(res.count)
        var originData = []
        for(var i=0; i<res.data.length; i++){
           var tmp = res.data[i]
           tmp["key"] = res.data[i].id
          //  tmp["date_joined"] = res.data[i].date_joined.slice(0, 19)
           originData.push(tmp)
        }
        setData(originData)
      }
    })
  }

  useEffect(()=>{
    fetchData(1)
  }, [])

  const togtherItem = async (page: number) => {
    request.post('/api/v1/admin/iots/softwares/sync/', {limit: pageSize, page: page,}).then((res: any)=>{
      if(res.code === 0){
        message.info('同步成功');
        fetchData(current)
      }else{
        message.info(res.msg);
      }
    })
  };

  const deleteItem = async (key: React.Key) => {
    // request.delete('/api/v1/admin/iots/softwares?id=' + key).then((res: any)=>{
      request.delete('/api/v1/admin/iots/softwares/' + key + '/').then((res: any)=>{
      if(res.code === 0){
        // message.info('删除成功');
        message.info(res.msg);
        fetchData(current)
      }else{
        message.info(res.msg);
      }
    })
  };

  const columns = [
    {
      title: '升级包ID',
      dataIndex: 'software_id',
      width: '15%',
      editable: false,
    },
    {
      title: '升级包版本名称',
      dataIndex: 'name',
      width: '18%',
      editable: false,
    },
    {
      title: '升级包版本号',
      dataIndex: 'version',
      width: '15%',
      editable: false,
    },
    {
      title: '创建时间',
      dataIndex: 'add_time',
      width: '20%',
      editable: false,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_: any, record: Item) => {
        return (
          <div>
            <Popconfirm title="确定删除吗?" onConfirm={() => deleteItem(record.key)} style={{ marginRight: 8 }}>
              <a>删除</a>
            </Popconfirm>
            {/* &nbsp;&nbsp;
            <Popconfirm title="确定同步吗?" onConfirm={() => togtherItem(record.key)} style={{ marginRight: 8 }}>
              <a>同步</a>
            </Popconfirm> */}
          </div>
        )
      },
    },
  ];

  const changePage = (page: any) => {
    setCurrent(page)
    fetchData(page)
  }

  const syncStart = (current: number) => {
    // setCurrent(current)
    togtherItem(current)
  }

  return (
    <div>
      <Button type="primary" className='oil-field-add-btn' onClick={ () => syncStart(current)}>同步</Button>
      <Form form={form} component={false}>
        <Table
          bordered
          dataSource={data}
          columns={columns}
          pagination={{
            onChange: changePage,
            total: total,
            current: current,
            pageSize: pageSize
          }}
        />
      </Form>
    </div>

  );
};

export default App;