import './App.css';

import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Header from './components/Admin/Header/header'
import Menu from './components/Admin/Menu/menu'
import Overview from './components/Admin/Overview/overview'
import CompanyList from './components/Admin/CompanyList/company_list'
import CompanyDetail from './components/Admin/CompanyDetail/company_detail'
import UserList from './components/Admin/UserList/user_list'
import UserDetail from './components/Admin/UserDetail/user_detail'
import OilFieldList from './components/Admin/OilFieldList/oil_field_list'
import OilFieldDetail from './components/Admin/OilFieldDetail/oil_field_detail'
import OilFieldEdit from './components/Admin/OilFieldEdit/oil_field_edit'
import OilWellList from './components/Admin/OilWellList/oil_well_list'
import OilWellDetail from './components/Admin/OilWellDetail/oil_well_detail'
import DeviceList from './components/Admin/DeviceList/device_list'
import DeviceDetail from './components/Admin/DeviceDetail/device_detail'
import DeviceEdit from './components/Admin/DeviceEdit/device_edit'
import RepairList from './components/Admin/RepairList/repair_list'
import RepairDetail from './components/Admin/RepairDetail/repair_detail'
import CollectBillList from './components/Admin/CollectBillList/collect_bill_list'
import CollectBillDetail from './components/Admin/CollectBillDetail/collect_bill_detail'
import CollectRecordList from './components/Admin/CollectRecordList/collect_record_list'
import CollectRecordDetail from './components/Admin/CollectRecordDetail/collect_record_detail'
import StorageRecordList from './components/Admin/StorageRecordList/storage_record_list'
import StorageRecordDetail from './components/Admin/StorageRecordDetail/storage_record_detail'
import UpgradeList from './components/Admin/UpgradeList/upgrade_list'
import UpgradeTaskList from './components/Admin/UpgradeTaskList/upgrade_task_list'
import UpgradeTaskDetail from './components/Admin/UpgradeTaskDetail/upgrade_task_detail'
import UpgradeTaskAdd from './components/Admin/UpgradeTaskAdd/upgrade_task_add'





import cookie from 'react-cookies'


function App(props: any) {
  const navigate = useNavigate();
  const params = useParams();

  useEffect(() =>{

    if(!cookie.load('token')){
      navigate('/login')
    }
  })

  return (
    <div className='bodyContainer'>
      <Header></Header>
      <div className="menu-container">
        <Menu></Menu>
      </div>
      <div className="table-container">
        {props.menu === 'overview' ? <Overview></Overview> : ""}
        {props.menu === 'company_list' ? <CompanyList></CompanyList> : ""}
        {props.menu === 'company_detail' ? <CompanyDetail id={params.id}></CompanyDetail> : ""}
        {props.menu === 'user_list' ? <UserList></UserList> : ""}
        {props.menu === 'user_detail' ? <UserDetail id={params.id}></UserDetail> : ""}
        {props.menu === 'oil_field_list' ? <OilFieldList></OilFieldList> : ""}
        {props.menu === 'oil_field_detail' ? <OilFieldDetail id={params.id}></OilFieldDetail> : ""}
        {props.menu === 'oil_field_edit' ? <OilFieldEdit type="edit" id={params.id}></OilFieldEdit> : ""}
        {props.menu === 'oil_field_add' ? <OilFieldEdit type="add"></OilFieldEdit> : ""}
        {props.menu === 'oil_well_list' ? <OilWellList></OilWellList> : ""}
        {props.menu === 'oil_well_detail' ? <OilWellDetail id={params.id}></OilWellDetail> : ""}
        {props.menu === 'device_list' ? <DeviceList></DeviceList> : ""}
        {props.menu === 'device_detail' ? <DeviceDetail id={params.id}></DeviceDetail> : ""}
        {props.menu === 'device_add' ? <DeviceEdit type="add"></DeviceEdit> : ""}
        {props.menu === 'device_edit' ? <DeviceEdit type="edit" id={params.id}></DeviceEdit> : ""}
        {props.menu === 'repair_list' ? <RepairList></RepairList> : ""}
        {props.menu === 'repair_detail' ? <RepairDetail id={params.id}></RepairDetail> : ""}
        {props.menu === 'collect_bill_list' ? <CollectBillList></CollectBillList> : ""}
        {props.menu === 'collect_bill_detail' ? <CollectBillDetail id={params.id}></CollectBillDetail> : ""}
        {props.menu === 'collect_record_list' ? <CollectRecordList></CollectRecordList> : ""}
        {props.menu === 'collect_record_detail' ? <CollectRecordDetail id={params.id}></CollectRecordDetail> : ""}
        {props.menu === 'storage_record_list' ? <StorageRecordList></StorageRecordList> : ""}
        {props.menu === 'storage_record_detail' ? <StorageRecordDetail id={params.id}></StorageRecordDetail> : ""}
        {props.menu === 'upgrade_list' ? <UpgradeList></UpgradeList> : ""}
        {props.menu === 'upgrade_task_list' ? <UpgradeTaskList></UpgradeTaskList> : ""}
        {props.menu === 'upgrade_task_detail' ? <UpgradeTaskDetail id={params.id}></UpgradeTaskDetail> : ""}
        {props.menu === 'upgrade_task_add' ? <UpgradeTaskAdd></UpgradeTaskAdd> : ""}
      </div>
    </div>
  )

}

export default App;
