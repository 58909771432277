import './repair_detail.css'
import * as echarts from 'echarts/core';
import { Form, Input, InputNumber, message} from 'antd';
import React, { useEffect, useState } from 'react';
import Request from '../../../request';
import { COLOR_LIST, COLOR_REVERSE_LIST } from '../../../config';


const App = (props: any) => {
  console.log('props=', props)

  const [statusMap, _] = useState({"complete": "已完成", "processing": "处理中", "untreated": "未处理"})


  let request = new Request({});
  const [data, setData] = useState<any>({});
  const [attachments, setAttachments] = useState<any>([]);

  const fetchData = (id: string) => {
    request.get('/api/v1/admin/repair/' + id + '/').then((res: any)=>{
      if(res.code === 0){
        console.log('res=', res.data)
        setData(res.data)
        setAttachments(res.data.attachments)
      }
    })
  }

  useEffect(()=>{
    fetchData(props.id)
  }, [])


  return (
    <div className="repair-detail-container">
      <div className="repair-detail-header">
        <div>报修单详情</div>
        <div className="repair-detail-info-list">
          <div className="repair-detail-info-item">ID:{data.id}</div>
          <div className="repair-detail-info-item">单号:{data.repair_number}</div>
          <div className="repair-detail-info-item">创建时间:{data.add_time}</div>
          <div className="repair-detail-info-item">申请人:{data.applicant__username}</div>
          <div className="repair-detail-info-item">隶属油井:{data.oil_field_name}</div>
          <div className="repair-detail-info-item">详细地址:{data.lbs_name}{data.floor? '-' + data.floor: ''}</div>
          <div className="repair-detail-info-item">标题:{data.title}</div>
          <div className="repair-detail-info-item">内容:{data.content}</div>
          <div className="repair-detail-info-item">状态:{statusMap[data.status]}</div>
          {data.status === 'complete'? <div className="repair-detail-info-item">维修时间:{data.repair_time}</div>: ""}
          {data.status === 'complete'? <div className="repair-detail-info-item">维修意见:{data.repair_note}</div>: ""}
          {data.status === 'complete'? <div className="repair-detail-info-item">维修人:{data.reply_person}</div>: ""}
          <div></div>
        </div>
        <div className="repair-detail-attachments">附件：
          {
              attachments.map((item: any, index: number) =>{
                if(item.file_type === 'image'){
                  return <img className="repair-detail-attachments-item" key={item.id} src={item.url} />
                }else{
                  return <video className="repair-detail-attachments-item" controls key={item.id} src={item.url}></video>
                }
              })
          }
        </div>
      </div>
    </div>
  );
};

export default App;