import './upgrade_task_add.css'

import { Button, Form, Input, Select, message} from 'antd';
import React, { useEffect, useState } from 'react';
import Request from '../../../request';
import { useNavigate } from 'react-router';


const App = (props: any) => {

  let request = new Request({});
  const [data, setData] = useState<any[]>([]);
  const [baoData, setBaoData] = useState<any[]>([]);
  const [addObj, setAddObj] = useState({
    software_id: '',
    oil_well_ids: [],
  })
  const navigate = useNavigate();
  // 获取油井列表
  const fetchData = () => {
    request.get('/api/v1/admin/oilwells/?limit=1000&page=1').then((res: any)=>{
      if(res.code === 0){
        const originData = []
        for(var i=0; i<res.data.length; i++){
           var tmp:any = {}
          //  field__id
           tmp["key"] = res.data[i].id
           tmp["label"] = res.data[i].name
           tmp["value"] = res.data[i].id
           originData.push(tmp)
        }
        console.log("originData===", originData)
        setData(originData)
      }
    })
  }
  // 获取升级包列表
  const fetchBaoData = () => {
    request.get('/api/v1/admin/iots/softwares/?limit=1000&page=1').then((res: any)=>{
      if(res.code === 0){
        const originData = []
        for(var i=0; i<res.data.length; i++){
           var tmp:any = {}
           tmp["key"] = res.data[i].id
           tmp["label"] = res.data[i].name
           tmp["value"] = res.data[i].id
           originData.push(tmp)
        }
        console.log("originData===", originData)
        setBaoData(originData)
      }
    })
  }

  useEffect(()=>{
    fetchData()
    fetchBaoData()
  }, [])
  const onFinish = () => {
    // let obj = {
    //   "software_id": 2, //版本主键id
    //   "oil_well_ids": [
    //   37
    //   ] //油井id列表
    // }
      // request.post('/api/v1/admin/iots/upgrade/', obj).then((res: any)=>{
        request.post('/api/v1/admin/iots/upgrade/', addObj).then((res: any)=>{
        if(res.code === 0){
          message.info(res.msg)
          setTimeout(()=>{
            navigate('/upgrade_task/list')
          }, 3000)
        }else{
          message.info(res.msg)
        }
      })
  }
  const baoHandleChange = (value: string) => {
    console.log(value,1);
    addObj.software_id = value
    setAddObj(addObj)
  };
  const oilHandleChange = (value: never[]) => {
    console.log(value,2);
    addObj.oil_well_ids = value
    setAddObj(addObj)
  };


  return (
    <div className="device-form-container">
      <Form className="device-form"
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
        component={false}
      >
        <Form.Item
          label="升级包"
          name="software_id"
          rules={[{ required: true, message: '请选择升级包' }]}
        >
          <Select
            allowClear
            options={baoData.map((item:any) => ({ label: item.label, value: item.value }))}
            value={addObj.software_id}
            onChange={baoHandleChange}
          ></Select>
        </Form.Item>
        <Form.Item
          label="油井"
          name="oil_well_ids"
          rules={[{ required: true, message: '请选择油井' }]}
        >
          <Select
            mode="multiple"
            allowClear
            defaultValue={[]}
            options={data.map((item:any) => ({ label: item.label, value: item.value }))}
            value={addObj.oil_well_ids}
            onChange={oilHandleChange}
          ></Select>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" onClick={onFinish}>
            提交
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default App;