import { Button, Result } from 'antd';
import React from 'react';

const App: React.FC = () => (
  <Result
    status="404"
    title="404"
    subTitle="对不起, 您访问的页面暂时不存在"
    extra={<Button type="primary">Back Home</Button>}
  />
);

export default App;