import './upgrade_task_detail.css'
import { Form, Table, Popconfirm, message} from 'antd';
import { useEffect, useState } from 'react';
import Request from '../../../request';
import {timestampToTime} from '../../../utils/date'
import { Link } from 'react-router-dom';


interface Item {
  key: string;
  name: string;
  add_time: string;
  is_active: boolean;
  status: number;
}

const App = (props: any) => {

  let request = new Request({});
  const [data, setData] = useState<any>({});
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(1)
  const [total, setTotal] = useState(0)
  const [pageSize] = useState(10)
  const [tableData, setTableData] = useState<any[]>([]);
  const [statusMap] = useState({"1": "是", "2": "否"})

  const fetchTableData = () => {
    request.get('/api/v1/admin/iots/upgrade/'+props.id+'/sub-tasks/').then((res: any)=>{
      console.log("tableData===", res)

      if(res.code === 0){
        setTotal(res.total)
        var originData = []
        for(var i=0; i<res.data.length; i++){
           var tmp = res.data[i]
           tmp["key"] = res.data[i].id
           tmp['deal_note'] = res.data[i]['deal_note'] ? res.data[i]['deal_note']: "上报正常"
           originData.push(tmp)
        }
        setTableData(originData)
      }
    })
  }

  const cancelTask = async (key: React.Key) => {
    const arrayId = []
    arrayId.push(key)
    request.post('/api/v1/admin/iots/upgrade/'+props.id+'/sub-tasks/cancel/',{sub_task_ids: arrayId}).then((res: any)=>{
      if(res.code === 0){
        message.info(res.msg);
        fetchTableData()
      }else{
        message.info(res.msg);
      }
    })
  };

  const againTask = async (key: React.Key) => {
    const arrayId = []
    arrayId.push(key)
    request.post('/api/v1/admin/iots/upgrade/'+props.id+'/sub-tasks/retry/',{sub_task_ids: arrayId}).then((res: any)=>{
      if(res.code === 0){
        message.info(res.msg);
        fetchTableData()
      }else{
        message.info(res.msg);
      }
    })
  };

  const deleteTask = async (key: React.Key) => {
    const arrayId = []
    arrayId.push(key)
    request.post('/api/v1/admin/iots/upgrade/'+props.id+'/sub-tasks/drop/',{sub_task_ids: arrayId}).then((res: any)=>{
      if(res.code === 0){
        message.info(res.msg);
        fetchTableData()
      }else{
        message.info(res.msg);
      }
    })
  };

  const columns = [
    {
      title: '升级任务ID',
      dataIndex: 'task_id',
      width: '9%',
      editable: true,
    },
    {
      title: '设备名称',
      dataIndex: 'device_name',
      width: '13%',
      editable: false,
    },
    {
      title: '设备ID',
      dataIndex: 'id',
      width: '10%',
      editable: true,
    },
    {
      title: '升级前版本号',
      dataIndex: 'pre_version',
      width: '10%',
      editable: true,
    },
    {
      title: '升级后版本号',
      dataIndex: 'post_version',
      width: '10%',
      editable: true,
    },
    {
      title: '状态更新时间',
      dataIndex: 'major_name',
      width: '17%',
      editable: true,
    },
    {
      title: '升级状态',
      dataIndex: 'task_state_name',
      width: '10%',
      editable: true,
    },
    {
      title: '升级结果',
      dataIndex: 'result_code_name',
      width: '10%',
      editable: true,
    },
    {
      title: '操作',
      dataIndex: 'operation',
      render: (_: any, record: Item) => {
        return (
          <div>
            <Popconfirm title="确定取消吗?" onConfirm={() => cancelTask(record.key)} style={{ marginRight: 8 }}>
              <a>取消</a>
            </Popconfirm>
            &nbsp;&nbsp;
            <Popconfirm title="确定重试吗?" onConfirm={() => againTask(record.key)} style={{ marginRight: 8 }}>
              <a>重试</a>
            </Popconfirm>
            &nbsp;&nbsp;
            <Popconfirm title="确定删除吗?" onConfirm={() => deleteTask(record.key)} style={{ marginRight: 8 }}>
              <a>删除</a>
            </Popconfirm>
          </div>
        )
      },
    },
  ]

  useEffect(()=>{
    fetchTableData()
  }, [])

  const changePage = (page: any) => {
    console.log('changePage', page)
    setCurrent(page)
    fetchTableData()
  }

  return (
    <div className="storage_record-detail-container">
      <div className="storage_record-detail-history-log">
        <Form form={form} component={false}>
          <Table
            bordered
            dataSource={tableData}
            columns={columns}
            pagination={{
              onChange: changePage,
              total: total,
              current: current,
              pageSize: pageSize
            }}
          />
        </Form>
      </div>

    </div>
  );
};

export default App;