import './oil_field_edit.css'

import { Form, Input, InputNumber, message} from 'antd';
import React, { useEffect, useState } from 'react';
import Request from '../../../request';


const App = (props: any) => {

  let request = new Request({});
  const [data, setData] = useState<any>({});

  const fetchData = (id: string) => {
    request.get('/api/v1/admin/oilfields/' + id + '/').then((res: any)=>{
      if(res.code === 0){
        console.log('res=', res.data)
        setData(res.data)
      }
    })
  }

  useEffect(()=>{
    console.log('props=', props)
    if(props.type === 'edit'){
      fetchData(props.id)
    }
  }, [])

  return (
    <div>
        <div>油田详情：</div>
        <div>id:{data.id}</div>
        <div>油田名称:{data.name}</div>
        <div>油井数量:{data.oil_total_number}</div>
        <div>省市区:{data.province}-{data.city}-{data.district}</div>
        <div>详细地址:{data.area}</div>
        <div>创建时间:{data.add_time}</div>
        <div>状态:{data.status}</div>
    </div>
  );
};

export default App;