import React, { useEffect } from 'react'
import * as echarts from 'echarts/core';
import cookie from 'react-cookies'
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
} from 'echarts/components';
import { PieChart, BarChart, LineChart} from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
import Request from '../../request';
import { companyUnitProcess } from '../../utils/companyProcess';



echarts.use([
    TitleComponent,
    TooltipComponent,
    LegendComponent,
    PieChart,
    BarChart,
    LineChart,
    CanvasRenderer,
    LabelLayout,
    GridComponent
  ]);


 function  MyLineChart(props: any) {

    useEffect(()=>{
        let user = cookie.load('user')
        if(!user){
          return
        }

        let myChart_exist = echarts.getInstanceByDom(document.getElementById(props.id) as HTMLElement);
        if (myChart_exist !== undefined) {
          myChart_exist.dispose()
        }

        let request = new Request({});

        let params = '?type=' + props.zone.type + '&name=' + props.zone.name
        request.get('/api/v1/report/oil_field_record_history/' + params).then((res: any)=>{
          // oilCopy: 0,
    // waterCopy: 0,
    // oilUnit: "",
    // waterUnit: "",
          let copyOilObj:any = companyUnitProcess(res.total_processed_oil)
          res.oilCopy = copyOilObj.number
          res.oilUnit = copyOilObj.companyUnit
          let copyWaterObj:any = companyUnitProcess(res.total_processed_water)
          res.waterCopy = copyWaterObj.number
          res.waterUnit = copyWaterObj.companyUnit
          props.setOilFieldHistory({
            oil: res.total_processed_oil,
            water: res.total_processed_water,
            oilCopy: res.oilCopy,
            oilUnit: res.oilUnit,
            waterCopy: res.waterCopy,
            waterUnit: res.waterUnit,
          })
          // console.log(res,'waterUnitwaterUnit')
          var data = []
          var data1 = []
          var data2 = []

          for(var i=0; i<res.data.length; i++){
            data.push(res.data[i].month)
            data1.push(res.data[i].processed_oil)
            data2.push(res.data[i].processed_water)
          }

          let myChart = echarts.init(document.getElementById(props.id) as HTMLElement, {renderer: 'svg'}, {devicePixelRatio: 2.5})

          function calMax(arr: number[]) {
            var max = Math.max.apply(null, arr); // 获取最大值方法
            var maxint = Math.ceil(max / 5); // 向上以5的倍数取整
            var maxval = maxint * 5 + 5; // 最终设置的最大值
            return maxval; // 输出最大值
          }
          // 获取最小值方法
          function calMin(arr: number[]) {
            var min = Math.min.apply(null, arr); // 获取最小值方法
            var minint = Math.floor(min / 1); // 向下以1的倍数取整
            var minval = minint * 1 - 5; // 最终设置的最小值
            if (minval < 0){
              minval = 0
            }
            return minval; // 输出最小值
          }

          // let data1 = [820, 932, 901, 934, 1290, 1330, 1320];
          // let data2 = [300, 400, 200, 100, 600, 380, 540];

          var maxData1 = calMax(data1);
          var maxData2 = calMax(data2);
          var minData1 = calMin(data1);
          var minData2 = calMin(data2);


          let option = {
            grid: {
              right: 70,
            },
            xAxis: {
              type: 'category',
              data: data,
              axisTick: {
                show: false
              },
              axisLine: {
                lineStyle:{color:'#51EDFF'}
              },
              // axisLabel: {
              //   interval: 0
              // }
            },
            textStyle: {fontFamily: 'din_black_italic_font'},

            yAxis: [
              {
                type: 'value',
                axisLine:{
                  lineStyle:{color:'#02F6A4'},
                },
                min: minData1,
                max: maxData1,
                splitNumber: 5,
                interval: (maxData1 - minData1) / 5,

                splitLine: {
                  lineStyle: {
                    color: "rgba(82, 47, 47, 1)"
                  }
                  }
                },
              {
                type: 'value',
                axisLine:{
                  lineStyle: {color:'#20C4FF'},
                },
                min: minData2,
                max: maxData2,
                splitNumber: 5,
                interval: (maxData2 - minData2) / 5,
                splitLine: {
                  lineStyle: {
                    color: "#20416A"
                  }
                  }
              }
            ],
            series: [
              {
                data: data1,
                type: 'line',
                smooth: true,
                yAxisIndex: 0,
                color: '#02F6A4',
              },
              {
                data: data2,
                type: 'line',
                smooth: true,
                yAxisIndex: 1,
                color: '#20C4FF',
              },
            ]
          };

          myChart.setOption(option)
        })


    }, [props.zone])


    return (
        <div id={props.id} style={{ width: props.width, height: props.height }}></div>
    );

}


export default MyLineChart
